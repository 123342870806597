/* eslint-disable @typescript-eslint/no-explicit-any */
import PlayerHttp, { API_HOST } from '@/player/service/api'
import { Proto } from '@/player/model/proto'
import store, { State } from '@/store'
import {
  CustomDomainLoginActionPayload,
  PlayerAuthBlockPayload,
  PlayerAuthFailedPayload,
  PlayerCPActionPayload,
  PlayerLoginActionPayload,
  PlayerLogoutActionPayload
} from '@/store/player/auth/action'
import { EMPTY, Observable, of, throwError } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { ErrorCode, MessageError } from '@/player/model/error'
import app from '@/main'
import * as UAParser from 'ua-parser-js'
import { util } from 'protobufjs'
import { LoginToken, LogoData, RecommendList, TemplateData } from '@/player/model/json'
import { BrowserService } from '@/player/service/browser.service'
import 'url-search-params-polyfill'
import { catchError, delay, map, mergeMap, retryWhen, take, tap } from 'rxjs/operators'
import { PlayerStoreService } from '@/player/service/store.service'
import { ProgressData, StoreKey } from '@/player/model/constant'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ImageService } from '@/service/image.service'
import { AWSService } from '@/player/service/aws.service'
import Http from '@/service/api'
import { ProgressResult } from '@/model/constant'
import { PlayerStatService } from '@/player/service/stat.service'

const ogpCache: { [key: string]: Uint8Array } = {}
const ogpCacheKeys: { [key: string]: number } = {}

export class PlayerApiService {
  public static login(
    request: Proto.LoginRequest,
    ignoreCustomDomain?: boolean,
    oauthType?: number): Observable<string> {
    const ua = new UAParser().getResult()
    const device = ua.device
    if (device.type === 'mobile') {
      request.deviceType = 2
    } else if (device.type === 'tablet') {
      request.deviceType = 3
    } else if (device.type === 'smarttv') {
      request.deviceType = 4
    } else if (!device.type) {
      request.deviceType = 1
    } else {
      request.deviceType = 99
    }
    const osName = ua.os.name.toLowerCase()
    if (osName === 'ios' || osName === 'ipados') {
      request.osType = 1
    } else if (osName === 'android') {
      request.osType = 2
    } else if (osName.startsWith('window')) {
      request.osType = 11
    } else if (osName.startsWith('mac')) {
      request.osType = 12
    } else if (['centos', 'chromium os', 'fedora', 'firefox os', 'freebsd', 'debian', 'linux', 'pclinuxos', 'redhat', 'ubuntu', 'vectorlinux'].indexOf(osName) >= 0) {
      request.osType = 12
    } else {
      request.osType = 99
    }
    request.osVersion = ua.os.version
    request.viewerType = 101
    request.deviceModel = ua.browser.name
    request.deviceVersion = ua.browser.version
    request.domain = PlayerApiService.domain()
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const navigator = window.navigator as any
      const userLanguage = (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage ||
        navigator.browserLanguage
      let locale = ''
      if (userLanguage && userLanguage.length > 0) {
        const index = userLanguage.indexOf('-')
        if (index >= 0) {
          locale = userLanguage.substr(0, index)
        } else {
          locale = userLanguage
        }
      }
      request.locale = locale
    }
    return fromPromise(
      PlayerStoreService.default()
        .getItem(StoreKey.DEVICE_TOKEN)
        .catch(e => {
          app.$log?.error(e)
          return Promise.resolve('')
        })
        .then(token => {
          const customDomain = (app.$store?.state as State)?.meta?.customDomain
          if (token && (!customDomain || customDomain === window.location.hostname.toLowerCase())) {
            request.token = token as string
          }
          return PlayerHttp.instance().requestAPIProto('auth/viewer/login', {
            method: 'POST',
            data: Proto.LoginRequest.encode(request)
          })
        })
        .then(res => {
          if (res.data) {
            return util.utf8.read(res.data, 0, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerLoginNeed) {
            e = MessageError.from(ErrorCode.ServerLoginFail, (e as MessageError).error)
          }
          throw e
        })
    )
      .pipe(
        mergeMap(uuid => {
          if (uuid) {
            if (request.userId) {
              PlayerStoreService.default()
                .setItem(StoreKey.UUID + '_' + request.userId, uuid)
                .catch(e => {
                  app.$log?.error(e)
                })
            }
            return PlayerApiService.companyUID()
              .pipe(map(cp => uuid))
          }
          return of(uuid)
        }),
        mergeMap(uuid => {
          if (uuid) {
            return PlayerApiService.isBlock()
              .pipe(map(a => uuid))
          }
          return of(uuid)
        }),
        mergeMap(uuid => {
          if (uuid && app && app.$store && (app.$store.state as State).meta.isCustom) {
            return PlayerApiService.getLoginToken()
              .pipe(
                map(token => {
                  app.$store.commit(new CustomDomainLoginActionPayload(token))
                  return uuid
                }),
                catchError(e => {
                  app.$log?.error(e)
                  return of(uuid)
                })
              )
          }
          return of(uuid)
        }),
        mergeMap(uuid => {
          if (!ignoreCustomDomain && app && app.$store && (app.$store.state as State).meta.isCustom) {
            const form = document.createElement('form')
            form.method = 'POST'
            form.action = `${process.env.VUE_APP_PLAYER_API_HOST}auth/viewer/loginForm`

            for (const key in request) {
              // eslint-disable-next-line no-prototype-builtins
              if (request.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input')
                hiddenField.type = 'hidden'
                hiddenField.value = request[key]
                if (key === 'deviceType' || key === 'osType' || key === 'osType' || key === 'thirdOAuthType' || key === 'oauthType') {
                  hiddenField.name = key + 'Int'
                } else if (key === 'asUser') {
                  hiddenField.name = key + 'Boolean'
                  hiddenField.value = request[key]?.value ? 'true' : 'false'
                } else {
                  hiddenField.name = key
                }
                form.appendChild(hiddenField)
              }
            }
            let url = window.location.href
            try {
              const urlOb = new URL(url)
              if (urlOb.pathname && urlOb.pathname.startsWith('/login')) {
                urlOb.pathname = '/'
                url = urlOb.toString()
              }
            } catch (e) {

            }
            const hiddenField = document.createElement('input')
            hiddenField.type = 'hidden'
            hiddenField.name = 'redirectUrl'
            hiddenField.value = url.replace(/&login-result=\d/g, '')
            form.appendChild(hiddenField)
            document.body.appendChild(form)
            form.submit()
            return EMPTY
          }
          return of(uuid)
        }),
        mergeMap(uuid => {
          if (uuid) {
            PlayerStatService.getInstance().isUserLogEnabled
              .then(enabled => {
                if (enabled) {
                  PlayerApiService.addLogUserAccess()
                    .catch(e => app.$log?.error(e))
                }
              })
            return PlayerApiService.findUser()
              .pipe(map(user => {
                store.commit(new PlayerLoginActionPayload(uuid, user))
                let logData: any[]
                if (request.password) {
                  logData = [1, 0, 0, 0, '', '']
                } else if (oauthType !== undefined) {
                  logData = [0, 0, 0, 1, oauthType, request.userId]
                } else if (request.jwt) {
                  logData = [0, 0, 1, 0, '', '']
                } else {
                  logData = [0, 0, 0, 0, '', '']
                }
                AWSService.getInstance().addLog(uuid, user, 'user-login',
                  ['byPassword', 'byUUID', 'byToken', 'byOAuth', 'oauthType', 'oauthEmail'],
                  logData)
                return uuid
              }))
          }
          return of(uuid)
        })
      )
  }

  public static authenticated(thirdOAuthType?: number | null): Observable<string | undefined> {
    let url = `auth/viewer/authenticated?d=${encodeURIComponent(PlayerApiService.domain())}`
    if (thirdOAuthType) {
      url += `&third-oauth=${thirdOAuthType}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url, {
        method: 'GET'
      })
        .then(res => {
          if (res.data) {
            return util.utf8.read(res.data, 0, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerLoginNeed) {
            store.commit(new PlayerAuthFailedPayload())
            return Promise.resolve(undefined)
          } else if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerSecurityIP) {
            return PlayerApiService.logout().toPromise()
              .then(() => undefined)
          }
          throw e
        })
    )
      .pipe(
        mergeMap(uuid => {
          if (uuid) {
            return PlayerApiService.findUser()
              .pipe(map(user => {
                store.commit(new PlayerLoginActionPayload(uuid, user))
                AWSService.getInstance().addLog(uuid, user, 'user-login',
                  ['byPassword', 'byUUID', 'byToken', 'byOAuth', 'oauthType', 'oauthEmail'],
                  [0, 1, 0, 0, '', ''])
                return uuid
              }))
          }
          return of(uuid)
        }),
        mergeMap(uuid => {
          if (uuid) {
            return PlayerApiService.companyUID()
              .pipe(map(cp => uuid))
          }
          return of(uuid)
        }),
        mergeMap(uuid => {
          if (uuid) {
            return PlayerApiService.isBlock()
              .pipe(map(a => uuid))
          }
          return of(uuid)
        }),
        tap(uuid => {
          const customDomain = (app.$store?.state as State)?.meta?.customDomain
          if (uuid && (!customDomain || customDomain === window.location.hostname.toLowerCase())) {
            PlayerStoreService.default()
              .getItem(StoreKey.DEVICE_TOKEN)
              .then(token => {
                if (token) {
                  PlayerApiService.updateDeviceToken(uuid, token as string)
                    .subscribe(() => {
                    }, e => {
                      app.$log?.error(e)
                    })
                }
              })
          }
        }),
        mergeMap(uuid => {
          if (uuid) {
            PlayerStatService.getInstance().isUserLogEnabled
              .then(enabled => {
                if (enabled) {
                  PlayerApiService.addLogUserAccess()
                    .catch(e => app.$log?.error(e))
                }
              })
          }
          if (uuid && app && app.$store && (app.$store.state as State).meta.isCustom) {
            return PlayerApiService.getLoginToken()
              .pipe(
                map(token => {
                  app.$store.commit(new CustomDomainLoginActionPayload(token))
                  return uuid
                }),
                catchError(e => {
                  app.$log?.error(e)
                  return of(uuid)
                })
              )
          }
          return of(uuid)
        })
      )
  }

  public static updateDeviceToken(uuid: string, token: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`auth/viewer/update?d=${encodeURIComponent(PlayerApiService.domain())}`, {
        method: 'POST',
        data: Proto.LoginRequest.encode({
          uuid,
          token
        })
      })
        .then(res => {
        })
    )
  }

  public static isBlock(): Observable<boolean> {
    const domain = PlayerApiService.domain()
    if (!domain) {
      store.commit(new PlayerAuthBlockPayload(false))
      return of(false)
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(`subscription/web/user/d/${encodeURIComponent(domain)}/block`, {
        method: 'GET'
      })
        .then(res => {
          const result = res.data !== undefined && res.data !== 1
          if (result) {
            store.commit(new PlayerAuthBlockPayload(result))
          }
          return result
        })
        .catch((e) => {
          app.$log?.error(e)
          return false
        })
    )
  }

  public static companyUID(): Observable<string | undefined> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto('auth/viewer/cp', {
        method: 'GET'
      })
        .then(res => {
          if (res.data) {
            const uid = util.utf8.read(res.data, 0, res.data.length)
            if (parseInt(uid, 10) === 0) {
              store.commit(new PlayerCPActionPayload(null))
            } else {
              store.commit(new PlayerCPActionPayload(uid))
            }
            return uid
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerLoginNeed) {
            store.commit(new PlayerAuthFailedPayload())
            return Promise.resolve(undefined)
          }
          throw e
        })
    )
  }

  public static getLoginToken(): Observable<LoginToken> {
    return fromPromise(
      PlayerHttp.instance().requestAPIJson('auth/viewer/loginSignToken', {
        method: 'GET'
      })
        .then(res => {
          return res.data as LoginToken
        })
    )
  }

  public static logout(): Observable<void> {
    let logPromise: Promise<void>
    const user = (app.$store?.state as State).playerAuth.user
    if (user) {
      logPromise = AWSService.getInstance().addLog(
        (app.$store.state as State).playerAuth.uuid || '',
        user,
        'user-logout'
      )
    } else {
      logPromise = Promise.resolve()
    }
    return fromPromise(
      logPromise.then(() => PlayerHttp.instance().requestAPIProto('auth/viewer/logoutProto', {
        method: 'GET'
      }))
        .catch((e) => {
          app.$log.error(e)
        })
        .then(() => {
          store.commit(new PlayerLogoutActionPayload())
        })
    )
  }

  public static forgot(email: string): Observable<void> {
    const data = new URLSearchParams()
    data.append('username', email)
    data.append('domain', PlayerApiService.domain())
    data.append('redirectUrl', window.location.origin + window.location.pathname + window.location.search)
    return fromPromise(
      PlayerHttp.instance().requestAPIProto('auth/forgot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data
      })
        .then(_ => {
        })
    )
  }

  public static preCheckToken(planUID: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(`subscription/web/pre/token?plan=${planUID}`, {
        method: 'GET'
      })
        .then(res => {
          return res.data as string
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerLoginNeed) {
            e = MessageError.from(ErrorCode.ServerLoginFail, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static preCheckWithUser(
    planUID?: string,
    playlistUID?: string,
    contentUID?: string,
    skip?: string,
    joinParams?: any): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(PlayerApiService.domain())}/preCheckUser`, {
        method: 'POST',
        data: JSON.stringify({
          planUID,
          playlistUID,
          contentUID,
          skipMail: skip,
          params: joinParams
        })
      })
        .then(res => {
          return res.data as string
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerLoginNeed) {
            e = MessageError.from(ErrorCode.ServerLoginFail, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static preRegister(
    email: string,
    recaptcha: string,
    planUID?: string,
    playlistUID?: string,
    contentUID?: string,
    skip?: string,
    joinParams?: any): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(PlayerApiService.domain())}/pre`, {
        method: 'POST',
        data: JSON.stringify({
          email,
          // recaptcha,
          planUID,
          playlistUID,
          contentUID,
          skipMail: skip,
          params: joinParams
        })
      })
        .then(res => {
          return res.data as string
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerLoginNeed) {
            e = MessageError.from(ErrorCode.ServerLoginFail, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static preRegisterOAuthUrl(
    type: number,
    planUID?: string,
    playlistUID?: string,
    contentUID?: string,
    skip?: string,
    redirect?: string,
    joinParams?: any): string {
    return this.loginOAuthUrl(type, redirect, planUID, playlistUID, contentUID, skip, undefined, undefined, joinParams)
  }

  public static loginOAuthUrl(
    type: number,
    redirectUrl?: string,
    planUID?: string,
    playlistUID?: string,
    contentUID?: string,
    skip?: string,
    admin?: boolean,
    queries?: string,
    joinParams?: any): string {
    let service = ''
    switch (type) {
      case 1:
        service = 'facebook'
        break
      case 2:
        service = 'twitter'
        break
      case 3:
        service = 'line'
        break
      case 4:
        service = 'apple'
        break
    }
    let url = API_HOST + `auth/user-oauth/loginToken?domain=${encodeURIComponent(PlayerApiService.domain())}&oauth-type=${type}`
    if (admin) {
      url = API_HOST + `auth/user-oauth/loginTokenAdmin?domain=${encodeURIComponent(PlayerApiService.domain())}&oauth-type=${type}`
    }
    if (redirectUrl) {
      url += `&redirect=${encodeURIComponent(redirectUrl)}`
    }
    if (planUID) {
      url += `&planUID=${planUID}`
    }
    if (playlistUID) {
      url += `&playlistUID=${playlistUID}`
    }
    if (contentUID) {
      url += `&contentUID=${contentUID}`
    }
    if (skip) {
      url += `&skipMail=${skip}`
    }
    if (queries) {
      url += `&lp-query=${encodeURIComponent(queries)}`
    }
    if (joinParams) {
      url += `&join-params=${encodeURIComponent(JSON.stringify(joinParams))}`
    }
    return API_HOST + `auth/user-oauth/authorize/${service}?redirect_uri=${encodeURIComponent(url)}&d=${encodeURIComponent(PlayerApiService.domain())}`
  }

  public static thirdLoginOAuthUrl(
    type: number,
    redirectUrl?: string,
    queries?: { [key: string]: string }): string {
    let service = ''
    switch (type) {
      case 10003:
        service = 'azure'
        break
    }
    let url = API_HOST + `auth/third-oauth/authorize/${service}?d=${encodeURIComponent(PlayerApiService.domain())}`
    if (redirectUrl) {
      url += `&redirect_uri=${encodeURIComponent(redirectUrl)}`
    }
    if (queries) {
      for (const key in queries) {
        url += `&${key}=${encodeURIComponent(queries[key])}`
      }
    }
    return url
  }

  static domain(): string {
    return store.state.playerMeta.subdomain
  }

  public static logo(): Observable<LogoData> {
    return fromPromise(PlayerHttp.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(PlayerApiService.domain())}/logo`,
      {
        method: 'GET'
      })
      .then(res => {
        return res.data as LogoData
      })
    )
  }

  public static template(): Observable<TemplateData> {
    return fromPromise(PlayerHttp.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(PlayerApiService.domain())}/template?content=0`, {
      method: 'GET'
    })
      .then(res => {
        return res.data as TemplateData
      }))
  }

  public static joinAvailable(playlistUID?: string): Observable<void> {
    let url = `subscription/web/d/${encodeURIComponent(PlayerApiService.domain())}/joinAvail`
    if (playlistUID) {
      url += '?pl=' + playlistUID
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(url,
        {
          method: 'GET'
        })
        .then(_ => {
          // throw MessageError.from(ErrorCode.JoinMaxUser)
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            e = MessageError.from(ErrorCode.NoPlaylistPlan, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static playlist(uid: string): Observable<Proto.IPlaylist> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${uid}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.Playlist.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static playlistAnonymous(uid: string): Observable<Proto.IPlaylist> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`subscription/web/d/${encodeURIComponent(PlayerApiService.domain())}/playlist/${uid}/proto`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.Playlist.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static playlistCommentVideo(
    uid: string,
    cuid: string,
    cmuid: string,
    uuid: number): Observable<Proto.IUploadFile> {
    let url = `player/playlist/${uid}/content/${cuid}/comment/${cmuid}/video/${uuid}`
    if (app && app.$store && (app.$store.state as State).meta.isCustom) {
      url += `?d=${encodeURIComponent(window.location.hostname)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UploadFile.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static contentRead(playlistUID: string, contentUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/read`,
        {
          method: 'PUT'
        }).then(res => {
      })
    )
  }

  public static contentList(uid: string, pageNum: number, pageRow: number): Observable<Proto.IContent[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${uid}/content?pageNum=${pageNum}&pageRow=${pageRow}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.ContentList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static contentListAnonymous(uid: string, pageNum: number, pageRow: number): Observable<Proto.IContent[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`subscription/web/d/${encodeURIComponent(PlayerApiService.domain())}/playlist/${uid}/content/proto?pageNum=${pageNum}&pageRow=${pageRow}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.ContentList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static content(uid: string, cuid: string): Observable<Proto.IContent> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${uid}/content/${cuid}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.Content.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static contentReactionUserList(puid: string,
    cuid: string,
    reaction: string,
    pageNum: number,
    pageRow: number): Observable<Proto.IUser[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${puid}/content/${cuid}/reaction-user?reaction=${reaction}&pageNum=${pageNum}&pageRow=${pageRow}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UserList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static contentStampPut(puid: string, cuid: string, ruid: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${puid}/content/${cuid}/like?reaction=${ruid}`,
        {
          method: 'PUT'
        })
        .then(res => {
          if (res.data) {
            return util.utf8.read(res.data, 0, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static contentReactionGroup(puid: string, cuid: string): Observable<Proto.IContent> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/playlist/${puid}/content/${cuid}/reaction-group`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.Content.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      })
    )
  }

  public static contentCommentVideo(
    playlistUID: string,
    contentUID: string,
    commentUID: string,
    fileUID: string): Observable<Proto.IUploadFile> {
    let url = `player/playlist/${playlistUID}/content/${contentUID}/comment/${commentUID}/video/${fileUID}`
    if (app && app.$store && (app.$store.state as State).meta.isCustom) {
      url += `?d=${encodeURIComponent(window.location.hostname)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UploadFile.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static reactionIconList(companyUID?: string,
    reactionUIDList?: string[],
    pageNum?: number,
    pageRow?: number): Observable<Proto.IReaction[]> {
    let url = 'player/community/reaction-list?'
    if (companyUID) {
      url += `&puid=${companyUID}`
    }
    if (reactionUIDList && reactionUIDList.length) {
      url += reactionUIDList.map(d => `&ruid=${d}`).join('')
    }
    if (pageNum) {
      url += `&pageNum=${pageNum}`
    }
    if (pageRow) {
      url += `&pageRow=${pageRow}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.ReactionList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static contentCommentReactionUserList(puid: string,
    cuid: string,
    cmuid: string,
    reaction: string,
    pageNum: number,
    pageRow: number): Observable<Proto.IUser[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${puid}/content/${cuid}/comment/${cmuid}/reaction-user?reaction=${reaction}&pageNum=${pageNum}&pageRow=${pageRow}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UserList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static contentCommentStampPut(puid: string, cuid: string, cmuid: string, ruid: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${puid}/content/${cuid}/comment/${cmuid}/like?reaction=${ruid}`,
        {
          method: 'PUT'
        })
        .then(res => {
          if (res.data) {
            return util.utf8.read(res.data, 0, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static contentCommentReactionGroup(puid: string,
    cuid: string,
    cmuid: string): Observable<Proto.IContentComment> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/playlist/${puid}/content/${cuid}/comment/${cmuid}/reaction-group`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.ContentComment.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      })
    )
  }

  // public static contentAnonymous(uid: string, cuid: string): Observable<Proto.IContent> {
  //   return fromPromise(
  //     PlayerHttp.instance().requestAPIProto(`subscription/web/d/${encodeURIComponent(PlayerApiService.domain())}/content/proto`,
  //       {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         data: JSON.stringify({
  //           uuidList: [cuid]
  //         })
  //       })
  //       .then(res => {
  //         if (res.data) {
  //           const list = Proto.ContentList.decode(res.data, res.data.length).value
  //           if (list.length > 0) {
  //             if (list[0].distribution === 0) {
  //               const err = MessageError.from(ErrorCode.NoContentPublic)
  //               const date = list[0].startDate ?? 0
  //               err.messageBlock = () => {
  //                 if (date) {
  //                   return app.$tc('player.error.code.' + err.code, undefined, { date: moment(date).format('LLL') })
  //                 }
  //                 return app.$tc('player.error.code.30002_no')
  //               }
  //               throw err
  //             }
  //             list[0].playlistUID = uid
  //             return list[0]
  //           }
  //           throw MessageError.from(ErrorCode.NoContent)
  //         }
  //         throw MessageError.Unexpected
  //       })
  //   )
  // }

  public static contentPlaylistUID(cuid: string, currentPUID?: string): Observable<string> {
    let url = `subscription/web/d/${encodeURIComponent(PlayerApiService.domain())}/content/playlistUID?uid=${cuid}`
    if (currentPUID) {
      url += `&puid=${currentPUID}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(url,
        {
          method: 'GET'
        })
        .then(res => {
          return res.data as string
        })
    )
  }

  public static session(): Observable<void> {
    let url = 'player/session'
    if (app && app.$store && (app.$store.state as State).meta.isCustom) {
      url += `?d=${encodeURIComponent(window.location.hostname)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(_ => {
        })
    )
  }

  public static video(contentUID: string, fileUID: string): Observable<Proto.IUploadFile> {
    let url = `player/video/${contentUID}/${fileUID}`
    if (app && app.$store && (app.$store.state as State).meta.isCustom) {
      url += `?d=${encodeURIComponent(window.location.hostname)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UploadFile.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static videoFileUrl(contentUID: string, fileUID: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/video/${contentUID}/${fileUID}/file`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return util.utf8.read(res.data, 0, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static videoQualityList(
    contentUID?: string,
    types?: number[],
    topicUID?: string,
    commentUID?: string): Observable<number> {
    return fromPromise(
      BrowserService.isEmeSupported()
        .then(eme => {
          const video = document.createElement('video')
          const parser = new UAParser().getResult()
          const ios = BrowserService.isIOS()
          // https://epiclabs-io.github.io/browser-media-support/
          // https://stackoverflow.com/questions/35086625/determine-drm-system-supported-by-browser
          let isFirefox = false
          try {
            isFirefox = parser.browser.name.toLowerCase() === 'firefox'
          } catch (e) {
            app.$log?.error(e)
          }
          const req = Proto.ViewerVideoListRequest.create({
            ios: false,
            android: false,
            tablet: false,
            pc: true,
            safari: BrowserService.isSafari(),
            rawCodec: JSON.stringify({ // maybe
              x264: {
                main: video.canPlayType('video/mp4; codecs="avc1.4d001f"') === 'probably',
                high: video.canPlayType('video/mp4; codecs="avc1.640029"') === 'probably'
              },
              hevc: {
                main: video.canPlayType('video/mp4; codecs="hvc1.1.2.L93.B0"') === 'probably'
              },
              vp9: {
                main: !isFirefox ? video.canPlayType('video/webm; codecs="vp9, opus"') === 'probably' : false
              }
            }),
            eme,
            app: false,
            osVersion: parser.browser.version,
            userAgent: window.navigator.userAgent,
            contentUID,
            topicUID,
            commentUID,
            types,
            customDomain: (app.$store?.state as State)?.meta?.isCustom ?? false
          })
          if (!BrowserService.isPC()) {
            req.pc = false
            if (ios) {
              req.ios = true
              req.tablet = BrowserService.isIpad()
              req.osVersion = parser.os.version
            } else if (BrowserService.isAndroid()) {
              req.android = true
              req.tablet = BrowserService.isAndroidTablet()
              req.osVersion = parser.os.version
            }
          }
          return PlayerHttp.instance().requestAPIProto('player/videoList',
            {
              method: 'POST',
              data: Proto.ViewerVideoListRequest.encode(req)
            })
        })
        .then(res => {
          if (res.data) {
            const str = util.utf8.read(res.data, 0, res.data.length)
            if (str) {
              return parseInt(str, 10)
            }
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static audioQualityList(contentUID: string, types: number[]): Observable<number> {
    return fromPromise(
      BrowserService.isEmeSupported()
        .then(eme => {
          const video = document.createElement('video')
          const parser = new UAParser().getResult()
          const ios = BrowserService.isIOS()
          // https://epiclabs-io.github.io/browser-media-support/
          // https://stackoverflow.com/questions/35086625/determine-drm-system-supported-by-browser
          let isFirefox = false
          try {
            isFirefox = parser.browser.name.toLowerCase() === 'firefox'
          } catch (e) {
            app.$log?.error(e)
          }
          const req = Proto.ViewerVideoListRequest.create({
            ios: false,
            android: false,
            tablet: false,
            pc: true,
            safari: BrowserService.isSafari(),
            rawCodec: JSON.stringify({ // maybe
              x264: {
                main: video.canPlayType('video/mp4; codecs="avc1.4d001f"') === 'probably',
                high: video.canPlayType('video/mp4; codecs="avc1.640029"') === 'probably'
              },
              hevc: {
                main: video.canPlayType('video/mp4; codecs="hvc1.1.2.L93.B0"') === 'probably'
              },
              vp9: {
                main: !isFirefox ? video.canPlayType('video/webm; codecs="vp9, opus"') === 'probably' : false
              }
            }),
            eme,
            app: false,
            osVersion: parser.browser.version,
            userAgent: window.navigator.userAgent,
            contentUID,
            types
          })
          if (!BrowserService.isPC()) {
            req.pc = false
            if (ios) {
              req.ios = true
              req.tablet = BrowserService.isIpad()
              req.osVersion = parser.os.version
            } else if (BrowserService.isAndroid()) {
              req.android = true
              req.tablet = BrowserService.isAndroidTablet()
              req.osVersion = parser.os.version
            }
          }
          return PlayerHttp.instance().requestAPIProto('player/audioList',
            {
              method: 'POST',
              data: Proto.ViewerVideoListRequest.encode(req)
            })
        })
        .then(res => {
          if (res.data) {
            const str = util.utf8.read(res.data, 0, res.data.length)
            if (str) {
              return parseInt(str, 10)
            }
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static addContentAccess(playlistUID: string, contentUID: string): Promise<void> {
    return PlayerHttp.instance().requestAPIProto(`player/stat/content/access?uid=${playlistUID}&cuid=${contentUID}`,
      {
        method: 'POST'
      })
      .then(res => {
      })
  }

  public static statDeviceKey(): Promise<string> {
    return PlayerHttp.instance().requestAPIProto('player/stat/device-key',
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return util.utf8.read(res.data, 0, res.data.length)
        }
        throw MessageError.Unexpected
      })
  }

  public static addContentPlay(req: Proto.IContentPlayStatRequest): Promise<Proto.IContentPlayStatResponse> {
    return PlayerHttp.instance().requestAPIProto('player/stat/content/play',
      {
        method: 'POST',
        data: Proto.ContentPlayStatRequest.encode(req)
      })
      .then(res => {
        if (res.data) {
          return Proto.ContentPlayStatResponse.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      })
  }

  public static addContentPlayHistory(req: Proto.IContentPlayStatRequest): Observable<void> {
    return fromPromise(PlayerHttp.instance().requestAPIProto('player/stat/content/play-history',
      {
        method: 'PUT',
        data: Proto.ContentPlayStatRequest.encode(req)
      })
      .then(res => {
      }))
  }

  public static addContentTrackMore(playlistUID: string, contentUID: string, eventUID: string): Promise<void> {
    return PlayerHttp.instance().requestAPIProto(`player/stat/event/track-more?uid=${playlistUID}&cuid=${contentUID}&euid=${eventUID}`,
      {
        method: 'POST'
      })
      .then(res => {
      })
  }

  public static resourceFile(contentUID: string, fileUID: string, additionalUID: string): Observable<string> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/video/${contentUID}/${fileUID}/file/${additionalUID}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return util.utf8.read(res.data, 0, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static thumbnailList(contentUID: string, fileUID: string): Observable<Proto.UploadFileOutput> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/video/${contentUID}/${fileUID}/thumbnail`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.UploadFileOutput.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static bookmarkList(
    contentUID: string,
    pageNum: number,
    pageRow: number): Observable<Proto.IContentBookmark[]> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/bookmark/${contentUID}?pageNum=${pageNum}&pageRow=${pageRow}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.ContentBookmarkList.decode(res.data, res.data.length).value
        }
        throw MessageError.Unexpected
      }))
  }

  public static addBookmark(contentUID: string, playlistUID: string, time: number): Observable<string> {
    const req = new Proto.ContentBookmark({
      contentUID,
      playlistUID,
      time
    })
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/bookmark/${contentUID}`,
      {
        method: 'POST',
        data: Proto.ContentBookmark.encode(req)
      })
      .then(res => {
        if (res.data) {
          return util.utf8.read(res.data, 0, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static deleteBookmark(bookmark: Proto.IContentBookmark): Observable<string> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/bookmark/${bookmark.contentUID}/delete`,
      {
        method: 'POST',
        data: Proto.ContentBookmark.encode(bookmark)
      })
      .then(res => {
        if (res.data) {
          return util.utf8.read(res.data, 0, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static additionalListCount(contentUID: string): Observable<number> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`subscription/web/d/${encodeURIComponent(PlayerApiService.domain())}/content/${contentUID}/additional/count`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return parseInt(util.utf8.read(res.data, 0, res.data.length), 10)
          }
          return 0
        })
    )
  }

  public static additionalList(contentUID: string): Observable<Proto.IUploadFileAdditional[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`subscription/web/d/${encodeURIComponent(PlayerApiService.domain())}/content/${contentUID}/additional`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UploadFileAdditionalList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static contactEmail(
    name: string,
    email: string,
    phone: string,
    body: string,
    contentUID?: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(PlayerApiService.domain())}/contact`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            name,
            email,
            phone,
            body,
            contentUID
          })
        })
        .then(res => {
          return res.data as string
        })
    )
  }

  public static liveContentEvent(
    contentUID: string,
    eventUID: string): Observable<Proto.IContentEvent> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/event/${eventUID}/admin-live-event`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.ContentEvent.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static updateLiveContentEvent(
    contentUID: string,
    eventUID: string,
    data: Proto.IContentEvent): Observable<Proto.IContentEvent> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/event/${eventUID}/admin-live-event`,
      {
        method: 'PUT',
        data: Proto.ContentEvent.encode(data)
      })
      .then(res => {
        if (res.data) {
          return Proto.ContentEvent.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static canJoinLive(
    playlistUID: string,
    contentUID: string,
    eventDateUID: string): Observable<void> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/event/${eventDateUID}/can-join-live?pl=${playlistUID}`,
      {
        method: 'GET'
      })
      .then(res => {
      }))
  }

  public static byteLiveDetail(
    playlistUID: string,
    contentUID: string,
    eventDateUID: string,
    host: boolean,
    force?: boolean): Observable<Proto.IByteLiveDetail> {
    let url = `player/content/${contentUID}/event/${eventDateUID}/byte-detail?host=${host ? 1 : 0}&pl=${playlistUID}`
    if (force) {
      url += `&f=${force ? 1 : 0}`
    }
    return fromPromise(PlayerHttp.instance().requestAPIProto(url,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.ByteLiveDetail.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static zoomUrl(
    playlistUID: string,
    contentUID: string,
    eventDateUID: string,
    host: boolean,
    force?: boolean): Observable<string> {
    let url = `player/content/${contentUID}/event/${eventDateUID}/zoom/start?host=${host ? 1 : 0}&pl=${playlistUID}`
    if (force) {
      url += `&f=${force ? 1 : 0}`
    }
    return fromPromise(PlayerHttp.instance().requestAPIProto(url,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return util.utf8.read(res.data, 0, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static zoomUrlRedirect(playlistUID: string, contentUID: string, eventDateUID: string): string {
    return `${API_HOST}player/content/${contentUID}/event/${eventDateUID}/zoom/start/re?pl=${playlistUID}`
  }

  public static endZoom(
    contentUID: string,
    eventDateUID: string): Observable<void> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/event/${eventDateUID}/zoom/end`,
      {
        method: 'PUT'
      })
      .then(() => {
      })
      .catch((e) => {
        if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
          e = MessageError.from(ErrorCode.NoZoomLive, (e as MessageError).error)
        }
        throw e
      }))
  }

  public static endByteLive(
    contentUID: string,
    eventDateUID: string): Observable<void> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/event/${eventDateUID}/byte-live/end`,
      {
        method: 'PUT'
      })
      .then(() => {
      })
      .catch((e) => {
        if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
          e = MessageError.from(ErrorCode.NoByteLive, (e as MessageError).error)
        }
        throw e
      }))
  }

  public static addByteLiveRecording(
    contentUID: string,
    eventDateUID: string): Observable<void> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/event/${eventDateUID}/byte-live/add-record`,
      {
        method: 'PUT'
      })
      .then(() => {
      }))
  }

  public static updateZoomNotification(
    playlistUID: string,
    contentUID: string,
    eventDateUID: string,
    notifyEmail: boolean,
    notifyFCM: boolean): Observable<void> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/event/${eventDateUID}/zoom/start/notification?n=${notifyEmail ? 1 : 0}&notifyFCM=${notifyFCM ? 1 : 0}&pl=${playlistUID}`,
      {
        method: 'POST'
      })
      .then(res => {
      }))
  }

  public static zoomSignature(
    contentUID: string,
    eventUID: string,
    eventDateUID: string,
    nickname?: string): Observable<Proto.IZoomMeetingSignature> {
    let url = `player/content/${contentUID}/event/${eventUID}/${eventDateUID}/zoom/signature?version=2`
    if (nickname) {
      url += `&name=${encodeURIComponent(nickname)}`
    }
    return fromPromise(PlayerHttp.instance().requestAPIProto(url,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.ZoomMeetingSignature.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      })
      .catch((e) => {
        if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
          e = MessageError.from(ErrorCode.NoZoomLive, (e as MessageError).error)
        }
        throw e
      })
    )
  }

  public static zoomJoin(
    contentUID: string,
    eventUID: string,
    eventDateUID: string): Observable<string> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/event/${eventUID}/${eventDateUID}/zoom/join`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return util.utf8.read(res.data, 0, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static checkZoomNickname(
    contentUID: string,
    eventUID: string,
    eventDateUID: string,
    nickname: string): Observable<boolean> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/event/${eventUID}/${eventDateUID}/zoom/nickname?name=${encodeURIComponent(nickname)}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return parseInt(util.utf8.read(res.data, 0, res.data.length), 10) > 0
        }
        throw MessageError.Unexpected
      }))
  }

  public static deleteZoomNickname(
    contentUID: string,
    eventUID: string,
    eventDateUID: string,
    nickname: string): Observable<void> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/event/${eventUID}/${eventDateUID}/zoom/nickname?name=${encodeURIComponent(nickname)}`,
      {
        method: 'DELETE'
      })
      .then(_ => {
      }))
  }

  public static chatUrl(
    contentUID: string,
    eventDateUID: string): Observable<string> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/event/${eventDateUID}/chat/url`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return util.utf8.read(res.data, 0, res.data.length)
        }
        throw MessageError.Unexpected
      })
      .catch((e) => {
        if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
          e = MessageError.from(ErrorCode.NoChatroom, (e as MessageError).error)
        }
        throw e
      })
    )
      .pipe(
        retryWhen(errors => errors.pipe(delay(2000), take(5), mergeMap(err => throwError(err))))
      )
  }

  public static findUser(includePhone?: boolean): Observable<Proto.IUser> {
    let url = 'player/user/find'
    if (includePhone) {
      url += `?ph=${includePhone ? 1 : 0}`
    }
    return fromPromise(PlayerHttp.instance().requestAPIProto(url, {
      method: 'GET'
    })
      .then(res => {
        if (res.data) {
          const user = Proto.User.decode(res.data, res.data.length)
          if (app.$store.getters.isCompanyOwner) {
            user.icon = user.companyIcon
          }
          return user
        }
        throw MessageError.Unexpected
      }))
  }

  public static findProfileUser(domain: string, userUID: string): Observable<Proto.IUser> {
    const uid = parseInt(userUID, 10)
    let url = `player/user/profile/${uid}`
    if (domain) {
      url += `?domain=${domain}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url, {
        method: 'GET'
      })
        .then(res => {
          if (res.data) {
            return Proto.User.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static findProfileInfo(domain: string, userUID: string): Observable<Proto.ICompanyUserProfile[]> {
    const uid = parseInt(userUID, 10)
    let url = `player/user/profile/${uid}/info`
    if (domain) {
      url += `?domain=${domain}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url, {
        method: 'GET'
      })
        .then(res => {
          if (res.data) {
            return Proto.CompanyUserProfileList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static saveUserIcon(file: File): Observable<void> {
    const formData = new FormData()
    formData.append('type', 'png')
    formData.append('file', file)
    return fromPromise(PlayerHttp.instance().requestAPIProto('player/user/updateIcon', {
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(_ => {
      }))
  }

  public static deleteUserIcon(): Observable<void> {
    return fromPromise(PlayerHttp.instance().requestAPIProto('player/user/icon', {
      method: 'DELETE'
    })
      .then(_ => {
      }))
  }

  public static eventProducts(
    playlistUID: string,
    contentUID: string,
    eventUID: string): Observable<Proto.IContentEventProduct[]> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/${eventUID}/product`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.ContentEventProductList.decode(res.data, res.data.length).value
        }
        throw MessageError.Unexpected
      }))
  }

  public static eventProductCount(
    playlistUID: string,
    contentUID: string,
    eventUID: string): Observable<number> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/${eventUID}/product/count`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return parseInt(util.utf8.read(res.data, 0, res.data.length), 10)
        }
        throw MessageError.Unexpected
      }))
  }

  public static addEventProductAccess(
    playlistUID: string,
    contentUID: string,
    eventUID: string,
    productUID: string): Promise<void> {
    return PlayerHttp.instance().requestAPIProto(`player/stat/event/product/access?uid=${playlistUID}&cuid=${contentUID}&euid=${eventUID}&puid=${productUID}`,
      {
        method: 'POST'
      })
      .then(_ => {

      })
  }

  public static chatLastMessageList(token: string): Observable<Proto.ILiveChat[]> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`socket/message?token=${token}&pageNum=0&pageRow=20`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.LiveChatList.decode(res.data, res.data.length).value
        }
        throw MessageError.Unexpected
      }))
  }

  public static chatroomInfo(token: string): Observable<Proto.IChatroomInfo> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`socket/room-info?token=${token}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.ChatroomInfo.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static liveUserList(token: string,
    pageNum: number,
    pageRow: number,
    search?: string): Observable<Proto.ILiveUser[]> {
    let url = `socket/user-list?token=${token}&pageNum=${pageNum}&pageRow=${pageRow}`
    if (search) {
      url += `&search=${encodeURIComponent(search)}`
    }
    return fromPromise(PlayerHttp.instance().requestAPIProto(url,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.LiveUserList.decode(res.data, res.data.length).value
        }
        throw MessageError.Unexpected
      }))
  }

  public static thirdPlayerUrl(playlistUID: string, contentUID: string, fileUID: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/video/${contentUID}/${fileUID}/third?pl=${playlistUID}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return util.utf8.read(res.data, 0, res.data.length) + `&api=${process.env.VUE_APP_PLAYER_API_TOKEN}`
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static thirdPlayerUrlRedirect(playlistUID: string, contentUID: string, fileUID: string): string {
    return `${API_HOST}player/video/${contentUID}/${fileUID}/third/re?pl=${playlistUID}`
  }

  public static thirdPlayerRawUrl(
    playlistUID: string,
    contentUID: string,
    eventUID: string,
    eventDateUID: string): Observable<string> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/event/${eventUID}/${eventDateUID}/third/raw?pl=${playlistUID}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return util.utf8.read(res.data, 0, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static thirdLiveHostUrl(
    playlistUID: string,
    contentUID: string,
    eventUID: string,
    eventDateUID: string): Observable<string> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/event/${eventUID}/${eventDateUID}/third/live?pl=${playlistUID}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return util.utf8.read(res.data, 0, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static thirdLiveHostNotification(
    playlistUID: string,
    contentUID: string,
    eventUID: string,
    eventDateUID: string,
    start: boolean,
    notifyEmail: boolean,
    notifyFCM: boolean): Observable<void> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/event/${eventUID}/${eventDateUID}/third/live/notification?pl=${playlistUID}&start=${start ? 1 : 0}&notifyEmail=${notifyEmail ? 1 : 0}&notifyFCM=${notifyFCM ? 1 : 0}`,
      {
        method: 'POST'
      })
      .then(res => {
      }))
  }

  public static thirdLiveHostNotificationOnly(
    playlistUID: string,
    contentUID: string,
    eventUID: string,
    eventDateUID: string): Observable<void> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/event/${eventUID}/${eventDateUID}/third/live/notification?pl=${playlistUID}`,
      {
        method: 'PUT'
      })
      .then(res => {
      }))
  }

  public static thirdLiveUrl(
    playlistUID: string,
    contentUID: string,
    eventUID: string,
    eventDateUID: string): Observable<string> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/event/${eventUID}/${eventDateUID}/third?pl=${playlistUID}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return util.utf8.read(res.data, 0, res.data.length) + `&api=${process.env.VUE_APP_PLAYER_API_TOKEN}`
        }
        throw MessageError.Unexpected
      }))
  }

  public static contentLiked(uid: string, cuid: string): Observable<boolean> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${uid}/content/${cuid}/like`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return parseInt(util.utf8.read(res.data, 0, res.data.length), 10) > 0
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static contentLikePut(uid: string, cuid: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${uid}/content/${cuid}/like`,
        {
          method: 'PUT'
        })
        .then(res => {
          if (res.data) {
            const count = util.utf8.read(res.data, 0, res.data.length)
            const user = (app.$store?.state as State).playerAuth.user
            if (user) {
              AWSService.getInstance().addLog(
                (app.$store?.state as State).playerAuth.uuid || '',
                user,
                'content-like',
                ['playlistUID', 'contentUID', 'like'],
                [uid, cuid, 1]
              )
            }
            return count
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static contentLikeDelete(uid: string, cuid: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${uid}/content/${cuid}/like`,
        {
          method: 'DELETE'
        })
        .then(res => {
          if (res.data) {
            const count = util.utf8.read(res.data, 0, res.data.length)
            const user = (app.$store?.state as State).playerAuth.user
            if (user) {
              AWSService.getInstance().addLog(
                (app.$store?.state as State).playerAuth.uuid || '',
                user,
                'content-like',
                ['playlistUID', 'contentUID', 'like'],
                [uid, cuid, 0]
              )
            }
            return count
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static commentList(
    uid: string,
    cuid: string,
    pageNum: number,
    pageRow: number,
    parentUID?: string): Observable<Proto.IContentComment[]> {
    let url = `player/playlist/${uid}/content/${cuid}/comment?pageNum=${pageNum}&pageRow=${pageRow}`
    if (parentUID) {
      url += `&parent=${parentUID}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.ContentCommentList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static commentPost(
    uid: string,
    cuid: string,
    comment: Proto.IContentComment,
    files: [File, HTMLImageElement | null][]): Observable<ProgressResult<Proto.IContentComment>> {
    return new Observable<ProgressResult<Proto.IContentComment>>(subscriber => {
      PlayerHttp.instance().requestAPIProto(`player/playlist/${uid}/content/${cuid}/comment`,
        {
          method: 'POST',
          data: Proto.ContentComment.encode(comment)
        })
        .then(res => {
          if (res.data) {
            return Proto.ContentComment.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .then(comment => {
          const uploadList: Promise<[string, string | null]>[] = []
          if (comment.fileList && comment.fileList.length && files.length) {
            const allFileSize = files.map(f => f[0]?.size ?? 0).reduce((a, b) => a + b, 0)
            for (let i = 0; i < files.length; ++i) {
              const fileDTO = comment.fileList[i]
              const fileType = files[i][0].type?.replace('image/jpg', 'image/jpeg') || ''
              let resizedFile: File | null = null
              if (files[i][1]) {
                const resized = ImageService.resizeImage(files[i][1] as HTMLImageElement, 600, 'image/jpeg')
                resizedFile = resized ? ImageService.decodeDataURIToFile(resized) : files[i][0]
              }
              const fileSize = files[i][0]?.size ?? 0
              const fileSizeAlpha = fileSize > 0 ? (fileSize / allFileSize) : 0
              let uploadProgress = 0
              const promises: Promise<AxiosResponse>[] = [axios.put(fileDTO.path as string, files[i][0],
                {
                  headers: { 'Content-Type': fileType },
                  onUploadProgress: (e) => {
                    const percentage = e.loaded / e.total
                    const alpha = percentage - uploadProgress
                    uploadProgress = percentage
                    subscriber.next({
                      progress: alpha * fileSizeAlpha
                    })
                  }
                })]
              if (resizedFile) {
                promises.push(axios.put(fileDTO.thumbnailPath as string, resizedFile,
                  {
                    headers: { 'Content-Type': 'image/jpeg' }
                  }))
              }
              uploadList.push(Promise.all(promises)
                .then((result) => {
                  const success = result.filter(res => res.status === 200).length === result.length
                  if (!success) {
                    throw MessageError.from(ErrorCode.FileUpload)
                  }
                  fileDTO.uploaded = {
                    has: true,
                    value: true
                  }
                  if (resizedFile) {
                    fileDTO.thumbnailUploaded = {
                      has: true,
                      value: true
                    }
                  } else {
                    fileDTO.thumbnailUploaded = {
                      has: true,
                      value: false
                    }
                  }
                  return [fileDTO.uid as string, resizedFile ? fileDTO.uid as string : null]
                }))
            }
          }
          if (!uploadList.length) {
            subscriber.next({
              data: comment
            })
            subscriber.complete()
            return
          }
          return Promise.all(uploadList)
            .then(uidList => {
              const req = Proto.ContentConvertRequest.create({
                uid: uidList.map(uid => uid[0]),
                thumbnailUID: uidList.map(uid => uid[1] as string).filter(uid => !!uid)
              })
              return PlayerHttp.instance().requestAPIProto('player/comment/convert',
                {
                  method: 'POST',
                  data: Proto.ContentConvertRequest.encode(req)
                })
            })
            .then(_ => {
              subscriber.next({
                data: comment
              })
              subscriber.complete()
            })
        })
        .catch(e => subscriber.error(e))
    })
  }

  public static commentPut(
    uid: string,
    cuid: string,
    comment: Proto.IContentComment,
    files: [File, HTMLImageElement | null, Proto.IUploadFile | null][]): Observable<ProgressResult<Proto.IContentComment>> {
    return new Observable<ProgressResult<Proto.ICommunityBoardComment>>(subscriber => {
      PlayerHttp.instance().requestAPIProto(`player/playlist/${uid}/content/${cuid}/comment`,
        {
          method: 'PUT',
          data: Proto.ContentComment.encode(comment)
        })
        .then(res => {
          if (res.data) {
            return Proto.ContentComment.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .then(comment => {
          const uploadList: Promise<[string, string | null]>[] = []
          if (comment.fileList && comment.fileList.length && files.length) {
            const newInputFiles: [File, HTMLImageElement | null][] = []
            const outputFiles: Proto.IUploadFile[] = []
            for (let index = Math.min(files.length, comment.fileList.length) - 1; index >= 0; --index) {
              const inputFile = files[index]
              if (!inputFile[2]) {
                newInputFiles.push([inputFile[0], inputFile[1]])
                outputFiles.push(comment.fileList[index])
              }
            }
            if (newInputFiles.length) {
              const allFileSize = newInputFiles.map(f => f[0]?.size ?? 0).reduce((a, b) => a + b, 0)
              for (let i = 0; i < newInputFiles.length; ++i) {
                const fileDTO = outputFiles[i]
                const fileType = newInputFiles[i][0].type?.replace('image/jpg', 'image/jpeg') || ''
                let resizedFile: File | null = null
                if (newInputFiles[i][1]) {
                  const resized = ImageService.resizeImage(newInputFiles[i][1] as HTMLImageElement, 600, 'image/jpeg')
                  resizedFile = resized ? ImageService.decodeDataURIToFile(resized) : newInputFiles[i][0]
                }
                const fileSize = newInputFiles[i][0]?.size ?? 0
                const fileSizeAlpha = fileSize > 0 ? (fileSize / allFileSize) : 0
                let uploadProgress = 0
                const promises: Promise<AxiosResponse>[] = [axios.put(fileDTO.path as string, newInputFiles[i][0],
                  {
                    headers: { 'Content-Type': fileType },
                    onUploadProgress: (e) => {
                      const percentage = e.loaded / e.total
                      const alpha = percentage - uploadProgress
                      uploadProgress = percentage
                      subscriber.next({
                        progress: alpha * fileSizeAlpha
                      })
                    }
                  })]
                if (resizedFile) {
                  promises.push(axios.put(fileDTO.thumbnailPath as string, resizedFile,
                    {
                      headers: { 'Content-Type': 'image/jpeg' }
                    }))
                }
                uploadList.push(Promise.all(promises)
                  .then((result) => {
                    const success = result.filter(res => res.status === 200).length === result.length
                    if (!success) {
                      throw MessageError.from(ErrorCode.FileUpload)
                    }
                    fileDTO.uploaded = {
                      has: true,
                      value: true
                    }
                    if (resizedFile) {
                      fileDTO.thumbnailUploaded = {
                        has: true,
                        value: true
                      }
                    } else {
                      fileDTO.thumbnailUploaded = {
                        has: true,
                        value: false
                      }
                    }
                    return [fileDTO.uid as string, resizedFile ? fileDTO.uid as string : null]
                  }))
              }
            }
          }
          if (!uploadList.length) {
            subscriber.next({
              data: comment
            })
            subscriber.complete()
            return
          }
          return Promise.all(uploadList)
            .then(uidList => {
              const req = Proto.ContentConvertRequest.create({
                uid: uidList.map(uid => uid[0]),
                thumbnailUID: uidList.map(uid => uid[1] as string).filter(uid => !!uid)
              })
              return PlayerHttp.instance().requestAPIProto('player/comment/convert',
                {
                  method: 'POST',
                  data: Proto.ContentConvertRequest.encode(req)
                })
            })
            .then(_ => {
              subscriber.next({
                data: comment
              })
              subscriber.complete()
            })
        })
        .catch(e => subscriber.error(e))
    })
  }

  public static commentDelete(uid: string, cuid: string, commentUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${uid}/content/${cuid}/comment/${commentUID}`,
        {
          method: 'DELETE'
        })
        .then(res => {
        })
    )
  }

  public static contentCommentLikePut(uid: string, cuid: string, commentUID: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${uid}/content/${cuid}/comment/${commentUID}/like`,
        {
          method: 'PUT'
        })
        .then(res => {
          if (res.data) {
            const count = util.utf8.read(res.data, 0, res.data.length)
            const user = (app.$store?.state as State).playerAuth.user
            if (user) {
              AWSService.getInstance().addLog(
                (app.$store?.state as State).playerAuth.uuid || '',
                user,
                'content-comment-like',
                ['playlistUID', 'contentUID', 'commentUID', 'like'],
                [uid, cuid, commentUID, 1]
              )
            }
            return count
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static contentCommentLikeDelete(uid: string, cuid: string, commentUID: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${uid}/content/${cuid}/comment/${commentUID}/like`,
        {
          method: 'DELETE'
        })
        .then(res => {
          if (res.data) {
            const count = util.utf8.read(res.data, 0, res.data.length)
            const user = (app.$store?.state as State).playerAuth.user
            if (user) {
              AWSService.getInstance().addLog(
                (app.$store?.state as State).playerAuth.uuid || '',
                user,
                'content-comment-like',
                ['playlistUID', 'contentUID', 'commentUID', 'like'],
                [uid, cuid, commentUID, 0]
              )
            }
            return count
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static contentCommentBlock(uid: string, cuid: string, commentUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${uid}/content/${cuid}/comment/${commentUID}/block`,
        {
          method: 'PUT'
        })
        .then(res => {
        })
    )
  }

  public static contentCommentUnblock(uid: string, cuid: string, commentUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${uid}/content/${cuid}/comment/${commentUID}/block`,
        {
          method: 'DELETE'
        })
        .then(res => {
        })
    )
  }

  public static contentCommentProfile(uid: string, cuid: string, commentUID: string): Observable<Proto.IUser> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${uid}/content/${cuid}/comment/${commentUID}/profile`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.User.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static contentViolation(uid: string, type: number, text: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/content/${uid}/violate`,
        {
          method: 'POST',
          data: Proto.ContentViolate.encode(Proto.ContentViolate.create({
            contentUID: uid,
            type,
            text
          }))
        })
        .then(res => {
        })
    )
  }

  public static contentCommentViolation(
    contentUID: string,
    commentUID: string,
    type: number,
    text: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/comment/${commentUID}/violate`,
        {
          method: 'POST',
          data: Proto.ContentCommentViolate.encode(Proto.ContentCommentViolate.create({
            commentUID: commentUID,
            type,
            text
          }))
        })
        .then(res => {
        })
    )
  }

  public static topicViolation(uid: string, type: number, text: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/topic/${uid}/violate`,
        {
          method: 'POST',
          data: Proto.TopicViolate.encode(Proto.TopicViolate.create({
            topicUID: uid,
            type,
            text
          }))
        })
        .then(res => {
        })
    )
  }

  public static commentViolation(uid: string, type: number, text: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/comment/${uid}/violate`,
        {
          method: 'POST',
          data: Proto.CommentViolate.encode(Proto.CommentViolate.create({
            commentUID: uid,
            type,
            text
          }))
        })
        .then(res => {
        })
    )
  }

  public static companyViolation(type: number, text: string): Observable<void> {
    const domain = PlayerApiService.domain()
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(`subscription/web/d/${domain}/violate`,
        {
          method: 'POST',
          data: JSON.stringify({
            type,
            text
          })
        })
        .then(res => {
        })
    )
  }

  public static maintenanceSchedule(): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIJson('schedule',
        {
          method: 'GET'
        })
        .then(res => {
          return res.data as string || ''
        })
    )
  }

  public static devicePlaying(): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto('auth/viewer/playing',
        {
          method: 'POST'
        })
        .then(res => {
        })
    )
  }

  public static scheduleList(
    uid: string,
    pageNum: number,
    pageRow: number,
    startDate?: string,
    endDate?: string,
    statusFilter?: number[]): Observable<Proto.IContentSchedule[]> {
    let url = `player/content/${uid}/schedule?pageNum=${pageNum}&pageRow=${pageRow}`
    if (startDate) {
      url += `&s=${startDate}`
    }
    if (endDate) {
      url += `&e=${endDate}`
    }
    if (statusFilter) {
      url += statusFilter.map(s => `&sf=${s}`).join('')
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.ContentScheduleList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static scheduleListByDate(
    uid: string,
    startDate: string,
    endDate: string): Observable<Proto.IContentSchedule[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/content/${uid}/schedule/date?s=${startDate}&e=${endDate}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.ContentScheduleList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static scheduleAdminUserList(
    uid: string,
    pageNum: number,
    pageRow?: number,
    status?: number[],
    startDate?: string,
    endDate?: string): Observable<Proto.IContentSchedule[]> {
    let url = `player/content/${uid}/schedule/adminuser?pageNum=${pageNum}&pageRow=${pageRow ?? '20'}&order=1`
    if (status) {
      url += status.map(s => `&sf=${s}`).join('')
    }
    if (startDate) {
      url += `&s=${encodeURIComponent(startDate)}`
    }
    if (endDate) {
      url += `&e=${encodeURIComponent(endDate)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.ContentScheduleList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static schedulePut(
    contentUID: string,
    scheduleUID: string,
    data: Proto.IContentScheduleDateUser): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/schedule/${scheduleUID}`,
        {
          method: 'PUT',
          data: Proto.ContentScheduleDateUser.encode(data)
        })
        .then(res => {
        })
    )
  }

  public static scheduleLimit(contentUID: string): Observable<[number, number]> {
    const domain = PlayerApiService.domain()
    if (!domain) {
      return of([0, 0])
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(domain)}/content/${contentUID}/schedule/limit`, {
        method: 'GET'
      })
        .then(res => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const result = res.data as any
          return [result.min as number, result.max as number]
        })
    )
  }

  public static scheduleCount(contentUID: string): Observable<number> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/schedule/count`, {
        method: 'GET'
      })
        .then(res => {
          if (res.data) {
            return parseInt(util.utf8.read(res.data, 0, res.data.length), 10)
          }
          return 0
        })
    )
  }

  public static scheduleUserInfo(
    contentUID: string,
    scheduleUID: string): Observable<Proto.IContentScheduleUserInfo[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/schedule/${scheduleUID}/form`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.ContentScheduleUserInfoList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static scheduleAdminNotify(
    contentUID: string,
    scheduleUID: string,
    startDate: string,
    endDate: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/schedule/${scheduleUID}/notify?s=${encodeURIComponent(startDate)}&e=${encodeURIComponent(endDate)}`,
        {
          method: 'PUT'
        })
        .then(res => {
        })
    )
  }

  public static scheduleParticipate(contentUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/schedule/participate`,
        {
          method: 'PUT'
        })
        .then(res => {
        })
    )
  }

  public static signingAdminSchedule(contentUID: string): Observable<Proto.ContentSchedule> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/schedule/signing/admin`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.ContentSchedule.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static signingAdminScheduleOne(contentUID: string, dateUserUID: string): Observable<Proto.ContentSchedule> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/schedule/signing/admin/${dateUserUID}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.ContentSchedule.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static signingLiveUrl(
    contentUID: string,
    scheduleUID: string,
    startDate: string,
    endDate: string): Observable<string> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/content/${contentUID}/schedule/${scheduleUID}/signing?s=${encodeURIComponent(startDate)}&e=${encodeURIComponent(endDate)}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return util.utf8.read(res.data, 0, res.data.length)
        }
        throw MessageError.Unexpected
      })
      .catch((e) => {
        if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
          e = MessageError.from(ErrorCode.NoSigningContent, (e as MessageError).error)
        }
        throw e
      })
    )
      .pipe(
        retryWhen(errors => errors.pipe(delay(2000), take(5), mergeMap(err => throwError(err))))
      )
  }

  public static signingUserDetail(token: string, userId: string): Observable<Proto.SigningUser> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`socket/signing/user?token=${token}&id=${userId}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.SigningUser.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static contentProducts(
    playlistUID: string,
    contentUID: string): Observable<Proto.IContentProduct[]> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/product`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.ContentProductList.decode(res.data, res.data.length).value
        }
        throw MessageError.Unexpected
      }))
  }

  public static contentProductCount(
    playlistUID: string,
    contentUID: string): Observable<number> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/product/count`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return parseInt(util.utf8.read(res.data, 0, res.data.length), 10)
        }
        throw MessageError.Unexpected
      }))
  }

  public static addContentProductAccess(
    playlistUID: string,
    contentUID: string,
    productUID: string): Promise<void> {
    return PlayerHttp.instance().requestAPIProto(`player/stat/content/product/access?uid=${playlistUID}&cuid=${contentUID}&puid=${productUID}`,
      {
        method: 'POST'
      })
      .then(_ => {

      })
  }

  public static commerceList(
    playlistUID: string,
    contentUID: string,
    pageNum: number,
    pageRow: number,
    isAdmin?: boolean): Observable<Proto.IContentCommerce[]> {
    let url = `player/playlist/${playlistUID}/content/${contentUID}/commerce?pageNum=${pageNum}&pageRow=${pageRow}`
    if (isAdmin) {
      url += '&admin=1'
    }
    return fromPromise(PlayerHttp.instance().requestAPIProto(url,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          const list = Proto.ContentCommerceList.decode(res.data, res.data.length).value
          for (const data of list) {
            const dataAny = (data as any)
            dataAny.soldout = true
            if (data.variationList) {
              const length = data.variationList.map(variation => {
                if (variation.productList) {
                  return variation.productList.filter(product => {
                    const quantity = product.quantity ?? -1
                    return quantity !== 0
                  }).length
                }
                return 0
              }).reduce((a, b) => a + b, 0)
              dataAny.soldout = length === 0

              const uidList = data.variationOrder ? data.variationOrder.split(',') : []
              let additional = data.variationList ?? []
              if (uidList.length > 0) {
                additional = additional.sort((a, b) => {
                  const index1 = uidList.indexOf(a.uid as string)
                  const index2 = uidList.indexOf(b.uid as string)
                  if (index1 >= 0) {
                    if (index2 >= 0) {
                      return index1 - index2
                    }
                    return -1
                  } else if (index2 >= 0) {
                    return 1
                  }
                  return -1
                })
              }
              data.variationList = additional
            }
          }
          return list
        }
        throw MessageError.Unexpected
      }))
  }

  public static commerceListCount(
    playlistUID: string,
    contentUID: string,
    isAdmin?: boolean): Observable<number> {
    let url = `player/playlist/${playlistUID}/content/${contentUID}/commerce/count`
    if (isAdmin) {
      url += '?admin=1'
    }
    return fromPromise(PlayerHttp.instance().requestAPIProto(url,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return parseInt(util.utf8.read(res.data, 0, res.data.length), 10)
        }
        throw MessageError.Unexpected
      }))
  }

  public static updateCommercePublicStatus(
    playlistUID: string,
    contentUID: string,
    data: Proto.IContentCommerce): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/commerce/${data.uid}/status`,
        {
          method: 'PUT',
          data: Proto.ContentCommerce.encode(data)
        })
        .then(res => {
        })
    )
  }

  public static updateCommerceProductQuantity(
    playlistUID: string,
    contentUID: string,
    commerceUID: string,
    data: Proto.IContentCommerceVariationProduct): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/commerce/${commerceUID}/quantity`,
        {
          method: 'PUT',
          data: Proto.ContentCommerceVariationProduct.encode(data)
        })
        .then(res => {
        })
    )
  }

  public static updateCommerceMain(
    playlistUID: string,
    contentUID: string,
    data: Proto.IContentCommerceMap): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/commerce-main`,
        {
          method: 'PUT',
          data: Proto.ContentCommerceMap.encode(data)
        })
        .then(res => {
        })
    )
  }

  public static getCommerce(
    playlistUID: string,
    contentUID: string,
    commerceUID: string): Observable<Proto.IContentCommerce> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/commerce/${commerceUID}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.ContentCommerce.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static commerceAdditionalVideo(
    playlistUID: string,
    contentUID: string,
    commerceUID: string,
    additionalUID: string): Observable<Proto.IUploadFile> {
    let url = `player/playlist/${playlistUID}/content/${contentUID}/commerce/${commerceUID}/video/${additionalUID}`
    if (app && app.$store && (app.$store.state as State).meta.isCustom) {
      url += `?d=${encodeURIComponent(window.location.hostname)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UploadFile.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static adminPlanList(
    pageNum: number,
    pageRow: number): Observable<any[]> {
    return fromPromise(PlayerHttp.instance().requestAPIJson(`player/community/plan?pageNum=${pageNum}&pageRow=${pageRow}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return res.data as any[]
        }
        throw MessageError.Unexpected
      }))
  }

  public static adminGroupList(
    pageNum: number,
    pageRow: number): Observable<any[]> {
    return fromPromise(PlayerHttp.instance().requestAPIJson(`player/community/group?pageNum=${pageNum}&pageRow=${pageRow}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return res.data as any[]
        }
        throw MessageError.Unexpected
      }))
  }

  public static boardForumList(
    boardUID: string,
    pageNum: number,
    pageRow: number,
    search?: string,
    sort?: number,
    topicCreatable?: boolean,
    forumUID?: string[]): Observable<Proto.ICommunityBoardForum[]> {
    if (!boardUID) {
      return of([])
    }
    let url = `player/community/${boardUID}/forum?pageNum=${pageNum}&pageRow=${pageRow}`
    if (search) {
      url += `&search=${encodeURIComponent(search)}`
    }
    if (sort) {
      url += `&sort=${sort}`
    }
    if (topicCreatable) {
      url += '&topic-creatable=1'
    }
    if (forumUID && forumUID.length) {
      url += forumUID.map(uid => `&forumUID=${uid}`).join('')
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardForumList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static boardForum(
    boardUID: string,
    forumUID: string): Observable<Proto.ICommunityBoardForum> {
    if (!boardUID || !forumUID) {
      return EMPTY
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/forum/${forumUID}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardForum.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch(e => {
          if ((e as MessageError).code === ErrorCode.NoContent) {
            return Promise.reject(MessageError.from(ErrorCode.NoForum, e))
          }
          return Promise.reject(e)
        })
    )
  }

  public static boardForumHasMustRead(
    boardUID: string,
    forumUID?: string): Observable<boolean> {
    if (!boardUID) {
      return EMPTY
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/forum/has-must-read` + (forumUID ? `?forumUID=${forumUID}` : ''),
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            const value = util.utf8.read(res.data, 0, res.data.length)
            return value === '1'
          }
          throw MessageError.Unexpected
        })
        .catch(e => {
          if ((e as MessageError).code === ErrorCode.NoContent) {
            return Promise.reject(MessageError.from(ErrorCode.NoForum, e))
          }
          return Promise.reject(e)
        })
    )
  }

  public static boardTopicList(
    boardUID: string,
    pageNum: number,
    pageRow: number,
    search?: string,
    tag?: string,
    sort?: number,
    userUID?: string,
    forumUID?: string,
    listType?: number,
    includeItem?: boolean): Observable<Proto.ICommunityBoardTopic[]> {
    let url = `player/community/${boardUID}/topic?pageNum=${pageNum}&pageRow=${pageRow}`
    if (search) {
      url += `&search=${encodeURIComponent(search)}`
    }
    if (tag) {
      url += `&tag=${tag}`
    }
    if (sort) {
      url += `&sort=${sort}`
    }
    if (userUID) {
      url += `&targetUser=${userUID}`
    }
    if (forumUID) {
      url += `&forumUID=${forumUID}`
    }
    if (listType) {
      url += `&listType=${listType}`
    }
    if (includeItem) {
      url += '&include-item=1'
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardTopicList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static boardTopicSearch(
    boardUID: string,
    search: string,
    tag?: string,
    forumUID?: string): Observable<Proto.ICommunityBoardTopic[]> {
    let url = `player/community/${boardUID}/topic/search?`
    if (search) {
      url += `&search=${encodeURIComponent(search)}`
    }
    if (tag) {
      url += `&tag=${tag}`
    }
    if (forumUID) {
      url += `&forumUID=${forumUID}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardTopicList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static insertBoardTopic(
    boardUID: string,
    data: Proto.ICommunityBoardTopic,
    files: [File, HTMLImageElement | null][],
    notify: boolean): Observable<ProgressResult<Proto.ICommunityBoardTopic>> {
    return new Observable<ProgressResult<Proto.ICommunityBoardTopic>>(subscriber => {
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/topic?notify=${notify ? 1 : 0}`,
        {
          method: 'POST',
          data: Proto.CommunityBoardTopic.encode(data)
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardTopic.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .then(comment => {
          const uploadList: Promise<[string, string | null]>[] = []
          if (comment.fileList && comment.fileList.length && files.length) {
            const allFileSize = files.map(f => f[0]?.size ?? 0).reduce((a, b) => a + b, 0)
            for (let i = 0; i < files.length; ++i) {
              const fileDTO = comment.fileList[i]
              const fileType = files[i][0].type?.replace('image/jpg', 'image/jpeg') || ''
              let resizedFile: File | null = null
              if (files[i][1]) {
                const resized = ImageService.resizeImage(files[i][1] as HTMLImageElement, 600, 'image/jpeg')
                resizedFile = resized ? ImageService.decodeDataURIToFile(resized) : files[i][0]
              }
              const fileSize = files[i][0]?.size ?? 0
              const fileSizeAlpha = fileSize > 0 ? (fileSize / allFileSize) : 0
              let uploadProgress = 0
              const promises: Promise<AxiosResponse>[] = [axios.put(fileDTO.path as string, files[i][0],
                {
                  headers: { 'Content-Type': fileType },
                  onUploadProgress: (e) => {
                    const percentage = e.loaded / e.total
                    const alpha = percentage - uploadProgress
                    uploadProgress = percentage
                    subscriber.next({
                      progress: alpha * fileSizeAlpha
                    })
                  }
                })]
              if (resizedFile) {
                promises.push(axios.put(fileDTO.thumbnailPath as string, resizedFile,
                  {
                    headers: { 'Content-Type': 'image/jpeg' }
                  }))
              }
              uploadList.push(Promise.all(promises)
                .then((result) => {
                  const success = result.filter(res => res.status === 200).length === result.length
                  if (!success) {
                    throw MessageError.from(ErrorCode.FileUpload)
                  }
                  fileDTO.uploaded = {
                    has: true,
                    value: true
                  }
                  if (resizedFile) {
                    fileDTO.thumbnailUploaded = {
                      has: true,
                      value: true
                    }
                  } else {
                    fileDTO.thumbnailUploaded = {
                      has: true,
                      value: false
                    }
                  }
                  return [fileDTO.uid as string, resizedFile ? fileDTO.uid as string : null]
                }))
            }
          }
          if (!uploadList.length) {
            subscriber.next({
              data: comment
            })
            subscriber.complete()
            return
          }
          return Promise.all(uploadList)
            .then(uidList => {
              const req = Proto.ContentConvertRequest.create({
                uid: uidList.map(uid => uid[0]),
                thumbnailUID: uidList.map(uid => uid[1] as string).filter(uid => !!uid)
              })
              return PlayerHttp.instance().requestAPIProto('player/community/topic/convert',
                {
                  method: 'POST',
                  data: Proto.ContentConvertRequest.encode(req)
                })
            })
            .then(_ => {
              subscriber.next({
                data: comment
              })
              subscriber.complete()
            })
        })
        .catch(e => {
          subscriber.error(e)
        })
    })
  }

  public static updateBoardTopic(
    boardUID: string,
    data: Proto.ICommunityBoardTopic,
    files: [File, HTMLImageElement | null, Proto.IUploadFile | null][]): Observable<ProgressResult<Proto.ICommunityBoardTopic>> {
    return new Observable<ProgressResult<Proto.ICommunityBoardTopic>>(subscriber => {
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/topic`,
        {
          method: 'PUT',
          data: Proto.CommunityBoardTopic.encode(data)
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardTopic.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .then(comment => {
          const uploadList: Promise<[string, string | null]>[] = []
          if (comment.fileList && comment.fileList.length && files.length) {
            const newInputFiles: [File, HTMLImageElement | null][] = []
            const outputFiles: Proto.IUploadFile[] = []
            for (let index = Math.min(files.length, comment.fileList.length) - 1; index >= 0; --index) {
              const inputFile = files[index]
              if (!inputFile[2]) {
                newInputFiles.push([inputFile[0], inputFile[1]])
                outputFiles.push(comment.fileList[index])
              }
            }
            if (newInputFiles.length) {
              const allFileSize = newInputFiles.map(f => f[0]?.size ?? 0).reduce((a, b) => a + b, 0)
              for (let i = 0; i < newInputFiles.length; ++i) {
                const fileDTO = outputFiles[i]
                const fileType = newInputFiles[i][0].type?.replace('image/jpg', 'image/jpeg') || ''
                let resizedFile: File | null = null
                if (newInputFiles[i][1]) {
                  const resized = ImageService.resizeImage(newInputFiles[i][1] as HTMLImageElement, 600, 'image/jpeg')
                  resizedFile = resized ? ImageService.decodeDataURIToFile(resized) : newInputFiles[i][0]
                }
                const fileSize = newInputFiles[i][0]?.size ?? 0
                const fileSizeAlpha = fileSize > 0 ? (fileSize / allFileSize) : 0
                let uploadProgress = 0
                const promises: Promise<AxiosResponse>[] = [axios.put(fileDTO.path as string, newInputFiles[i][0],
                  {
                    headers: { 'Content-Type': fileType },
                    onUploadProgress: (e) => {
                      const percentage = e.loaded / e.total
                      const alpha = percentage - uploadProgress
                      uploadProgress = percentage
                      subscriber.next({
                        progress: alpha * fileSizeAlpha
                      })
                    }
                  })]
                if (resizedFile) {
                  promises.push(axios.put(fileDTO.thumbnailPath as string, resizedFile,
                    {
                      headers: { 'Content-Type': 'image/jpeg' }
                    }))
                }
                uploadList.push(Promise.all(promises)
                  .then((result) => {
                    const success = result.filter(res => res.status === 200).length === result.length
                    if (!success) {
                      throw MessageError.from(ErrorCode.FileUpload)
                    }
                    fileDTO.uploaded = {
                      has: true,
                      value: true
                    }
                    if (resizedFile) {
                      fileDTO.thumbnailUploaded = {
                        has: true,
                        value: true
                      }
                    } else {
                      fileDTO.thumbnailUploaded = {
                        has: true,
                        value: false
                      }
                    }
                    return [fileDTO.uid as string, resizedFile ? fileDTO.uid as string : null]
                  }))
              }
            }
          }
          if (!uploadList.length) {
            subscriber.next({
              data: comment
            })
            subscriber.complete()
            return
          }
          return Promise.all(uploadList)
            .then(uidList => {
              const req = Proto.ContentConvertRequest.create({
                uid: uidList.map(uid => uid[0]),
                thumbnailUID: uidList.map(uid => uid[1] as string).filter(uid => !!uid)
              })
              return PlayerHttp.instance().requestAPIProto('player/community/topic/convert',
                {
                  method: 'POST',
                  data: Proto.ContentConvertRequest.encode(req)
                })
            })
            .then(_ => {
              subscriber.next({
                data: comment
              })
              subscriber.complete()
            })
        })
        .catch(e => {
          subscriber.error(e)
        })
    })
  }

  public static deleteBoardTopic(
    boardUID: string,
    topicUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}`,
        {
          method: 'DELETE'
        })
        .then(res => {
        })
    )
  }

  public static boardTopic(
    boardUID: string,
    topicUID: string,
    ignoreCustomAssetDomain?: boolean): Observable<Proto.ICommunityBoardTopic> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}` + (ignoreCustomAssetDomain ? '?ica=1' : ''),
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardTopic.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch(e => {
          if ((e as MessageError).code === ErrorCode.NoContent) {
            return Promise.reject(MessageError.from(ErrorCode.NoTopic, e))
          }
          return Promise.reject(e)
        })
    )
  }

  public static boardTopicItemFileURL(boardUID: string, topicUID: string, itemUID: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/item/${itemUID}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return util.utf8.read(res.data, 0, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch(e => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerTrafficExceed) {
            return Promise.reject(MessageError.from(ErrorCode.ServerTrafficExceedDownload))
          }
          return Promise.reject(e)
        })
    )
  }

  public static boardTopicReactionUserList(
    uid: string,
    tuid: string,
    reaction: string,
    pageNum: number,
    pageRow: number): Observable<Proto.IUser[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${uid}/${tuid}/reaction-user?reaction=${reaction}&pageNum=${pageNum}&pageRow=${pageRow}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UserList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static boardTopicReactionInfo(uid: string, reactionUIDList?: string[]): Observable<Proto.IReaction[]> {
    let url = `player/community/${uid}/reaction-list?`
    if (reactionUIDList && reactionUIDList.length) {
      url += reactionUIDList.map(d => `&ruid=${d}`).join('')
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.ReactionList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static boardTopicVideo(
    boardUID: string,
    topicUID: string,
    fileUID: string): Observable<Proto.IUploadFile> {
    let url = `player/community/${boardUID}/topic/${topicUID}/video/${fileUID}`
    if (app && app.$store && (app.$store.state as State).meta.isCustom) {
      url += `?d=${encodeURIComponent(window.location.hostname)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UploadFile.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static boardTopicLikePut(boardUID: string, topicUID: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/like`,
        {
          method: 'PUT'
        })
        .then(res => {
          if (res.data) {
            const count = util.utf8.read(res.data, 0, res.data.length)
            const user = (app.$store?.state as State).playerAuth.user
            if (user) {
              AWSService.getInstance().addLog(
                (app.$store?.state as State).playerAuth.uuid || '',
                user,
                'board-topic-like',
                ['boardUID', 'topicUID', 'like', 'reactionUID'],
                [boardUID, topicUID, 1, '1']
              )
            }
            return count
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static boardTopicLikeDelete(
    boardUID: string,
    topicUID: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/like`,
        {
          method: 'DELETE'
        })
        .then(res => {
          if (res.data) {
            const count = util.utf8.read(res.data, 0, res.data.length)
            const user = (app.$store?.state as State).playerAuth.user
            if (user) {
              AWSService.getInstance().addLog(
                (app.$store?.state as State).playerAuth.uuid || '',
                user,
                'board-topic-like',
                ['boardUID', 'topicUID', 'like', 'reactionUID'],
                [boardUID, topicUID, 0, '1']
              )
            }
            return count
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static boardTopicStampPut(uid: string, tuid: string, ruid: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${uid}/${tuid}/like?reaction=${ruid}`,
        {
          method: 'PUT'
        })
        .then(res => {
          if (res.data) {
            const count = util.utf8.read(res.data, 0, res.data.length)
            const user = (app.$store?.state as State).playerAuth.user
            if (user) {
              AWSService.getInstance().addLog(
                (app.$store?.state as State).playerAuth.uuid || '',
                user,
                'board-topic-like',
                ['boardUID', 'topicUID', 'like', 'reactionUID'],
                [uid, tuid, 1, ruid]
              )
            }
            return count
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static boardTopicReactionGroup(uid: string, tuid: string): Observable<Proto.ICommunityBoardTopic> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/community/${uid}/${tuid}/reaction-group`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.CommunityBoardTopic.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      })
    )
  }

  public static boardTopicCommentStampPut(
    uid: string,
    tuid: string,
    cmuid: string,
    ruid: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${uid}/${tuid}/comment/${cmuid}/like/?reaction=${ruid}`,
        {
          method: 'PUT'
        })
        .then(res => {
          if (res.data) {
            return util.utf8.read(res.data, 0, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static boardTopicCommentReactionGroup(
    uid: string,
    tuid: string,
    cmuid: string): Observable<Proto.ICommunityBoardComment> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/community/${uid}/${tuid}/comment/${cmuid}/reaction-group`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.CommunityBoardComment.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      })
    )
  }

  public static boardTopicCommentReactionUserList(
    uid: string,
    tuid: string,
    cmuid: string,
    reaction: string,
    pageNum: number,
    pageRow: number): Observable<Proto.IUser[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${uid}/${tuid}/comment/${cmuid}/reaction-user?reaction=${reaction}&pageNum=${pageNum}&pageRow=${pageRow}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UserList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static boardTopicBlock(boardUID: string, topicUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/block`,
        {
          method: 'PUT'
        })
        .then(res => {
        })
    )
  }

  public static boardTopicUnblock(
    boardUID: string,
    topicUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/block`,
        {
          method: 'DELETE'
        })
        .then(res => {
        })
    )
  }

  public static boardTopicProfile(boardUID: string, topicUID: string): Observable<Proto.IUser> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/profile`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.User.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static boardTopicRead(
    boardUID: string,
    topicUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/read`,
        {
          method: 'PUT'
        })
        .then(res => {
        })
        .catch(e => {
          if ((e as MessageError).code === ErrorCode.NoContent) {
            return Promise.reject(MessageError.from(ErrorCode.NoTopic, e))
          }
          return Promise.reject(e)
        })
    )
  }

  public static boardTopicReadUserList(
    boardUID: string,
    topicUID: string,
    pageNum: number,
    pageRow: number): Observable<Proto.IUser[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/read-user?pageNum=${pageNum}&pageRow=${pageRow}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UserList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
        .catch(e => {
          if ((e as MessageError).code === ErrorCode.NoContent) {
            return Promise.reject(MessageError.from(ErrorCode.NoTopic, e))
          }
          return Promise.reject(e)
        })
    )
  }

  public static boardTopicBookmarkPut(
    boardUID: string,
    topicUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/bookmark`,
        {
          method: 'PUT'
        })
        .then(res => {
        })
        .catch(e => {
          if ((e as MessageError).code === ErrorCode.NoContent) {
            return Promise.reject(MessageError.from(ErrorCode.NoTopic, e))
          }
          return Promise.reject(e)
        })
    )
  }

  public static boardTopicBookmarkDelete(
    boardUID: string,
    topicUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/bookmark`,
        {
          method: 'DELETE'
        })
        .then(res => {
        })
        .catch(e => {
          if ((e as MessageError).code === ErrorCode.NoContent) {
            return Promise.reject(MessageError.from(ErrorCode.NoTopic, e))
          }
          return Promise.reject(e)
        })
    )
  }

  public static boardTopicCommentList(
    boardUID: string,
    topicUID: string,
    pageNum: number,
    pageRow: number,
    parentUID?: string): Observable<Proto.ICommunityBoardComment[]> {
    let url = `player/community/${boardUID}/${topicUID}/comment?pageNum=${pageNum}&pageRow=${pageRow}`
    if (parentUID) {
      url += `&parent=${parentUID}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardCommentList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static boardTopicCommentPost(
    boardUID: string,
    topicUID: string,
    comment: Proto.ICommunityBoardComment,
    files: [File, HTMLImageElement | null][]): Observable<ProgressResult<Proto.ICommunityBoardComment>> {
    return new Observable<ProgressResult<Proto.ICommunityBoardComment>>(subscriber => {
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/comment`,
        {
          method: 'POST',
          data: Proto.CommunityBoardComment.encode(comment)
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardComment.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .then(comment => {
          const uploadList: Promise<[string, string | null]>[] = []
          if (comment.fileList && comment.fileList.length && files.length) {
            const allFileSize = files.map(f => f[0]?.size ?? 0).reduce((a, b) => a + b, 0)
            for (let i = 0; i < files.length; ++i) {
              const fileDTO = comment.fileList[i]
              const fileType = files[i][0].type?.replace('image/jpg', 'image/jpeg') || ''
              let resizedFile: File | null = null
              if (files[i][1]) {
                const resized = ImageService.resizeImage(files[i][1] as HTMLImageElement, 600, 'image/jpeg')
                resizedFile = resized ? ImageService.decodeDataURIToFile(resized) : files[i][0]
              }
              const fileSize = files[i][0]?.size ?? 0
              const fileSizeAlpha = fileSize > 0 ? (fileSize / allFileSize) : 0
              let uploadProgress = 0
              const promises: Promise<AxiosResponse>[] = [axios.put(fileDTO.path as string, files[i][0],
                {
                  headers: { 'Content-Type': fileType },
                  onUploadProgress: (e) => {
                    const percentage = e.loaded / e.total
                    const alpha = percentage - uploadProgress
                    uploadProgress = percentage
                    subscriber.next({
                      progress: alpha * fileSizeAlpha
                    })
                  }
                })]
              if (resizedFile) {
                promises.push(axios.put(fileDTO.thumbnailPath as string, resizedFile,
                  {
                    headers: { 'Content-Type': 'image/jpeg' }
                  }))
              }
              uploadList.push(Promise.all(promises)
                .then((result) => {
                  const success = result.filter(res => res.status === 200).length === result.length
                  if (!success) {
                    throw MessageError.from(ErrorCode.FileUpload)
                  }
                  fileDTO.uploaded = {
                    has: true,
                    value: true
                  }
                  if (resizedFile) {
                    fileDTO.thumbnailUploaded = {
                      has: true,
                      value: true
                    }
                  } else {
                    fileDTO.thumbnailUploaded = {
                      has: true,
                      value: false
                    }
                  }
                  return [fileDTO.uid as string, resizedFile ? fileDTO.uid as string : null]
                }))
            }
          }
          if (!uploadList.length) {
            subscriber.next({
              data: comment
            })
            subscriber.complete()
            return
          }
          return Promise.all(uploadList)
            .then(uidList => {
              const req = Proto.ContentConvertRequest.create({
                uid: uidList.map(uid => uid[0]),
                thumbnailUID: uidList.map(uid => uid[1] as string).filter(uid => !!uid)
              })
              return PlayerHttp.instance().requestAPIProto('player/community/comment/convert',
                {
                  method: 'POST',
                  data: Proto.ContentConvertRequest.encode(req)
                })
            })
            .then(_ => {
              subscriber.next({
                data: comment
              })
              subscriber.complete()
            })
        })
        .catch(e => subscriber.error(e))
    })
  }

  public static boardTopicCommentPut(
    boardUID: string,
    topicUID: string,
    comment: Proto.ICommunityBoardComment,
    files: [File, HTMLImageElement | null, Proto.IUploadFile | null][]): Observable<ProgressResult<Proto.ICommunityBoardComment>> {
    return new Observable<ProgressResult<Proto.ICommunityBoardComment>>(subscriber => {
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/comment`,
        {
          method: 'PUT',
          data: Proto.CommunityBoardComment.encode(comment)
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardComment.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .then(comment => {
          const uploadList: Promise<[string, string | null]>[] = []
          if (comment.fileList && comment.fileList.length && files.length) {
            const newInputFiles: [File, HTMLImageElement | null][] = []
            const outputFiles: Proto.IUploadFile[] = []
            for (let index = Math.min(files.length, comment.fileList.length) - 1; index >= 0; --index) {
              const inputFile = files[index]
              if (!inputFile[2]) {
                newInputFiles.push([inputFile[0], inputFile[1]])
                outputFiles.push(comment.fileList[index])
              }
            }
            if (newInputFiles.length) {
              const allFileSize = newInputFiles.map(f => f[0]?.size ?? 0).reduce((a, b) => a + b, 0)
              for (let i = 0; i < newInputFiles.length; ++i) {
                const fileDTO = outputFiles[i]
                const fileType = newInputFiles[i][0].type?.replace('image/jpg', 'image/jpeg') || ''
                let resizedFile: File | null = null
                if (newInputFiles[i][1]) {
                  const resized = ImageService.resizeImage(newInputFiles[i][1] as HTMLImageElement, 600, 'image/jpeg')
                  resizedFile = resized ? ImageService.decodeDataURIToFile(resized) : newInputFiles[i][0]
                }
                const fileSize = newInputFiles[i][0]?.size ?? 0
                const fileSizeAlpha = fileSize > 0 ? (fileSize / allFileSize) : 0
                let uploadProgress = 0
                const promises: Promise<AxiosResponse>[] = [axios.put(fileDTO.path as string, newInputFiles[i][0],
                  {
                    headers: { 'Content-Type': fileType },
                    onUploadProgress: (e) => {
                      const percentage = e.loaded / e.total
                      const alpha = percentage - uploadProgress
                      uploadProgress = percentage
                      subscriber.next({
                        progress: alpha * fileSizeAlpha
                      })
                    }
                  })]
                if (resizedFile) {
                  promises.push(axios.put(fileDTO.thumbnailPath as string, resizedFile,
                    {
                      headers: { 'Content-Type': 'image/jpeg' }
                    }))
                }
                uploadList.push(Promise.all(promises)
                  .then((result) => {
                    const success = result.filter(res => res.status === 200).length === result.length
                    if (!success) {
                      throw MessageError.from(ErrorCode.FileUpload)
                    }
                    fileDTO.uploaded = {
                      has: true,
                      value: true
                    }
                    if (resizedFile) {
                      fileDTO.thumbnailUploaded = {
                        has: true,
                        value: true
                      }
                    } else {
                      fileDTO.thumbnailUploaded = {
                        has: true,
                        value: false
                      }
                    }
                    return [fileDTO.uid as string, resizedFile ? fileDTO.uid as string : null]
                  }))
              }
            }
          }
          if (!uploadList.length) {
            subscriber.next({
              data: comment
            })
            subscriber.complete()
            return
          }
          return Promise.all(uploadList)
            .then(uidList => {
              const req = Proto.ContentConvertRequest.create({
                uid: uidList.map(uid => uid[0]),
                thumbnailUID: uidList.map(uid => uid[1] as string).filter(uid => !!uid)
              })
              return PlayerHttp.instance().requestAPIProto('player/community/comment/convert',
                {
                  method: 'POST',
                  data: Proto.ContentConvertRequest.encode(req)
                })
            })
            .then(_ => {
              subscriber.next({
                data: comment
              })
              subscriber.complete()
            })
        })
        .catch(e => subscriber.error(e))
    })
  }

  public static boardTopicCommentDelete(boardUID: string, topicUID: string, commentUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/comment/${commentUID}`,
        {
          method: 'DELETE'
        })
        .then(res => {
        })
    )
  }

  public static boardTopicCommentVideo(
    boardUID: string,
    commentUID: string,
    fileUID: string): Observable<Proto.IUploadFile> {
    let url = `player/community/${boardUID}/comment/${commentUID}/video/${fileUID}`
    if (app && app.$store && (app.$store.state as State).meta.isCustom) {
      url += `?d=${encodeURIComponent(window.location.hostname)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UploadFile.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static boardTopicCommentLikePut(boardUID: string, topicUID: string, commentUID: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/comment/${commentUID}/like`,
        {
          method: 'PUT'
        })
        .then(res => {
          if (res.data) {
            const count = util.utf8.read(res.data, 0, res.data.length)
            const user = (app.$store?.state as State).playerAuth.user
            if (user) {
              AWSService.getInstance().addLog(
                (app.$store?.state as State).playerAuth.uuid || '',
                user,
                'board-topic-comment-like',
                ['boardUID', 'topicUID', 'commentUID', 'like'],
                [boardUID, topicUID, commentUID, 1]
              )
            }
            return count
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static boardTopicCommentLikeDelete(
    boardUID: string,
    topicUID: string,
    commentUID: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/comment/${commentUID}/like`,
        {
          method: 'DELETE'
        })
        .then(res => {
          if (res.data) {
            const count = util.utf8.read(res.data, 0, res.data.length)
            const user = (app.$store?.state as State).playerAuth.user
            if (user) {
              AWSService.getInstance().addLog(
                (app.$store?.state as State).playerAuth.uuid || '',
                user,
                'board-topic-comment-like',
                ['boardUID', 'topicUID', 'commentUID', 'like'],
                [boardUID, topicUID, commentUID, 1]
              )
            }
            return count
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static boardTopicCommentBlock(boardUID: string, topicUID: string, commentUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/comment/${commentUID}/block`,
        {
          method: 'PUT'
        })
        .then(res => {
        })
    )
  }

  public static boardTopicCommentUnblock(
    boardUID: string,
    topicUID: string,
    commentUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/comment/${commentUID}/block`,
        {
          method: 'DELETE'
        })
        .then(res => {
        })
    )
  }

  public static boardTopicCommentProfile(
    boardUID: string,
    topicUID: string,
    commentUID: string): Observable<Proto.IUser> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/comment/${commentUID}/profile`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.User.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static boardTopicBadgeRead(boardUID: string, topicUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/${topicUID}/badge`,
        {
          method: 'PUT'
        })
        .then(res => {
        })
    )
  }

  public static addBoardPollAnswer(
    boardUID: string,
    data: Proto.ICommunityBoardPoll): Observable<Proto.ICommunityBoardPoll> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/poll`,
        {
          method: 'POST',
          data: Proto.CommunityBoardPoll.encode(data)
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardPoll.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static fixPoll(boardUID: string, pollUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/poll/${pollUID}/fix`,
        {
          method: 'POST'
        })
        .then(res => {
        })
    )
  }

  public static communityOGP(url: string): Observable<Proto.IOGPResponse> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/ogp?url=${encodeURIComponent(url)}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.OGPResponse.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static contentAdditionalList(
    playlistUID: string,
    contentUID: string): Observable<Proto.IContentAdditionalFile[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/additional-file`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.ContentAdditionalFileList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static contentAdditionalFileURL(playlistUID: string, contentUID: string, fileUID: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/additional-file/${fileUID}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return util.utf8.read(res.data, 0, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch(e => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerTrafficExceed) {
            return Promise.reject(MessageError.from(ErrorCode.ServerTrafficExceedDownload))
          }
          return Promise.reject(e)
        })
    )
  }

  public static userNotificationCount(): Observable<number> {
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(`subscription/web/user/d/${encodeURIComponent(PlayerApiService.domain())}/notification/count`, {
        method: 'GET'
      })
        .then(res => {
          return parseInt(res.data as string, 10)
        })
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static userNotificationList(pageNum: number, pageRow: number): Observable<any[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(`subscription/web/user/d/${encodeURIComponent(PlayerApiService.domain())}/notification?pageNum=${pageNum}&pageRow=${pageRow}`, {
        method: 'GET'
      })
        .then(res => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return res.data as any[]
        })
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static userNotificationOne(uid: string): Observable<any> {
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(`subscription/web/user/notification/${uid}`, {
        method: 'GET'
      })
        .then(res => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return res.data as any
        })
    )
  }

  public static userNotificationRead(uid: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(`subscription/web/user/d/${encodeURIComponent(PlayerApiService.domain())}/notification/${uid}`, {
        method: 'PUT'
      })
        .then(res => {
        })
    )
  }

  public static userNotificationReadAll(): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(`subscription/web/user/d/${encodeURIComponent(PlayerApiService.domain())}/notification`, {
        method: 'PUT'
      })
        .then(res => {
        })
    )
  }

  public static transferOwnerOfGroup(guid: string, userUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${guid}/owner-transfer?toUser=${userUID}`,
        {
          method: 'POST'
        })
        .then(res => {
        })
        .catch(e => {
          if ((e as MessageError).code === ErrorCode.NoContent) {
            return Promise.reject(MessageError.from(ErrorCode.GroupChatNotOwner, e))
          }
          return Promise.reject(e)
        })
    )
  }

  public static exitUser(guid: string, uid: string): Observable<number> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${guid}/leave-user/${uid}`,
        {
          method: 'DELETE'
        })
        .then(res => {
          if (res.data) {
            return parseInt(util.utf8.read(res.data, 0, res.data.length), 10)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static updateBoardGroup(data: Proto.ICommunityBoardGroup): Observable<Proto.ICommunityBoardGroup> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto('player/chat/group',
        {
          method: 'PUT',
          data: Proto.CommunityBoardGroup.encode(data)
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardGroup.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static updateBoardGroupIcon(
    data: Proto.ICommunityBoardGroup,
    newImage?: Proto.IUploadFile): Observable<ProgressResult<Proto.ICommunityBoardGroup>> {
    return new Observable<ProgressResult<Proto.ICommunityBoardGroup>>(subscriber => {
      PlayerHttp.instance().requestAPIProto('player/chat/group/icon',
        {
          method: 'PUT',
          data: Proto.CommunityBoardGroup.encode(data)
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardGroup.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .then(comment => {
          const uploadList: Promise<[string, string | null]>[] = []
          if (comment.icon && newImage) {
            const allFileSize = newImage.fileSize ?? 0
            const fileDTO = comment.icon
            const fileType = 'image/png'
            const fileSize = newImage.fileSize ?? 0
            const fileSizeAlpha = fileSize > 0 ? (fileSize / allFileSize) : 0
            let uploadProgress = 0
            const promises: Promise<AxiosResponse>[] = [axios.put(fileDTO.path as string, ImageService.decodeDataURIToFile(newImage.fileURL || ''),
              {
                headers: { 'Content-Type': fileType },
                onUploadProgress: (e) => {
                  const percentage = e.loaded / e.total
                  const alpha = percentage - uploadProgress
                  uploadProgress = percentage
                  subscriber.next({
                    progress: alpha * fileSizeAlpha
                  })
                }
              })]
            promises.push(axios.put(fileDTO.thumbnailPath as string, ImageService.decodeDataURIToFile(newImage.thumbnailImageURL || ''),
              {
                headers: { 'Content-Type': 'image/jpeg' }
              }))
            uploadList.push(Promise.all(promises)
              .then((result) => {
                const success = result.filter(res => res.status === 200).length === result.length
                if (!success) {
                  throw MessageError.from(ErrorCode.FileUpload)
                }
                fileDTO.uploaded = {
                  has: true,
                  value: true
                }
                fileDTO.thumbnailUploaded = {
                  has: true,
                  value: true
                }
                return [fileDTO.uid as string, fileDTO.uid as string]
              }))
          }
          if (!uploadList.length) {
            subscriber.next({
              data: comment
            })
            subscriber.complete()
            return
          }
          return Promise.all(uploadList)
            .then(uidList => {
              const req = Proto.ContentConvertRequest.create({
                uid: uidList.map(uid => uid[0]),
                thumbnailUID: uidList.map(uid => uid[1] as string).filter(uid => !!uid)
              })
              return PlayerHttp.instance().requestAPIProto(`player/chat/group/${data.uid}/icon/convert`,
                {
                  method: 'POST',
                  data: Proto.ContentConvertRequest.encode(req)
                })
            })
            .then(_ => {
              if (comment.icon) {
                comment.icon.uploaded = Proto.NullBoolean.create({
                  has: true,
                  value: true
                })
                comment.icon.thumbnailUploaded = Proto.NullBoolean.create({
                  has: true,
                  value: true
                })
              }
              subscriber.next({
                data: comment
              })
              subscriber.complete()
            })
        })
        .catch(e => subscriber.error(e))
    })
  }

  public static updateBoardGroupUser(guid: string, data: Proto.ICommunityBoardGroup): Observable<number> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${guid}/invite`,
        {
          method: 'PUT',
          data: Proto.CommunityBoardGroup.encode(data)
        })
        .then(res => {
          if (res.data) {
            return parseInt(util.utf8.read(res.data, 0, res.data.length), 10)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static dmRoomList(companyUID: string): Observable<Proto.IBoardChatRoom[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/dm-room/${companyUID}/list`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.BoardChatRoomList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static groupRoomList(boardUID: string,
    pageNum: number,
    pageRow: number,
    text?: string): Observable<Proto.ICommunityBoardGroup[]> {
    let url = `player/chat/group?uid=${boardUID}&pageNum=${pageNum}&pageRow=${pageRow}`
    if (text) {
      url += `&text=${encodeURIComponent(text)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardGroupList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static updateBoardGroupPassword(data: Proto.ICommunityBoardGroup): Observable<Proto.ICommunityBoardGroup> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto('player/chat/group/pw',
        {
          method: 'PUT',
          data: Proto.CommunityBoardGroup.encode(data)
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardGroup.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static deleteBoardGroup(
    groupUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${groupUID}`,
        {
          method: 'DELETE'
        })
        .then(res => {
        })
    )
  }

  public static chatLiveUrl(groupUID: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${groupUID}/live`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return util.utf8.read(res.data, 0, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static iconTemplateList(types: number[],
    companyUID?: string,
    pageNum?: number,
    pageRow?: number): Observable<Proto.IIconTemplate[]> {
    let url = `player/chat/icon-template?pageNum=${pageNum ?? 0}&pageRow=${pageRow ?? 20}` + types.map(t => `&t=${t}`).join('')
    if (companyUID) {
      url += `&cuid=${companyUID}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.IconTemplateList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static groupEmoticonList(companyUID?: string, emoticonUIDList?: string[]): Observable<Proto.IEmoticon[]> {
    let url = 'player/chat/emoticon-list?'
    if (companyUID) {
      url += `&cuid=${companyUID}`
    }
    if (emoticonUIDList && emoticonUIDList.length) {
      url += emoticonUIDList.map(d => `&euid=${d}`).join('')
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.EmoticonList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static groupEmoticonAll(companyUID?: string,
    pageNum?: number,
    pageRow?: number): Observable<Proto.IEmoticon[]> {
    let url = `player/chat/emoticon-list?pageNum=${pageNum ?? 0}&pageRow=${pageRow ?? 100}`
    if (companyUID) {
      url += `&cuid=${companyUID}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.EmoticonList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static groupStampGroup(companyUID?: string,
    pageNum?: number,
    pageRow?: number): Observable<Proto.IStampGroup[]> {
    let url = `player/chat/stamp/group?pageNum=${pageNum ?? 0}&pageRow=${pageRow ?? 100}`
    if (companyUID) {
      url += `&cuid=${companyUID}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.StampGroupList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static groupStampGroupAll(stampGroupUID: string,
    pageNum?: number,
    pageRow?: number): Observable<Proto.IStamp[]> {
    const url = `player/chat/stamp/group/${stampGroupUID}?pageNum=${pageNum ?? 0}&pageRow=${pageRow ?? 100}`
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.StampList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static chatEmoticonPut(groupUID: string, chatId: string, emoticonUID: string): Observable<Proto.IBoardChat> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${groupUID}/message/${chatId}/emoticon?emoticon=${emoticonUID}`,
        {
          method: 'PUT'
        })
        .then(res => {
          if (res.data) {
            return Proto.BoardChat.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static chatEmoticonUserList(
    groupUID: string,
    chatId: string,
    emoticonUID: string,
    pageNum: number,
    pageRow: number): Observable<Proto.IUser[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${groupUID}/message/${chatId}/emoticon-user?emoticon=${emoticonUID}&pageNum=${pageNum}&pageRow=${pageRow}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UserList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static findGroup(groupUID: string): Observable<Proto.ICommunityBoardGroup> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${groupUID}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardGroup.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static postGroup(groupData: Proto.ICommunityBoardGroup): Observable<Proto.ICommunityBoardGroup> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto('player/chat/group',
        {
          method: 'POST',
          data: Proto.CommunityBoardGroup.encode(groupData)
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardGroup.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static getMessageUnreadCount(companyUID: string, groupUID: string): Observable<Proto.IChatUnreadCount> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/unread/${companyUID}/${groupUID}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.ChatUnreadCount.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static getMessageRead(groupUID: string, idList: string[]): Observable<Proto.IBoardChat[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${groupUID}/get-read`,
        {
          method: 'POST',
          data: Proto.BoardChatList.encode({
            value: idList.map(id => Proto.BoardChat.create({ id }))
          })
        })
        .then(res => {
          if (res.data) {
            return Proto.BoardChatList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static updateMessageRead(groupUID: string, idList: string[]): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${groupUID}/read`,
        {
          method: 'PUT',
          data: Proto.BoardChatList.encode({
            value: idList.map(id => Proto.BoardChat.create({ id }))
          })
        })
        .then(res => {
        })
    )
  }

  public static chatUserList(boardUID: string,
    pageNum: number,
    pageRow: number,
    text?: string,
    excludeGroup?: string): Observable<Proto.IUser[]> {
    let url = `player/chat/user?&uid=${boardUID}&pageNum=${pageNum}&pageRow=${pageRow}`
    if (text) {
      url += `&text=${text}`
    }
    if (excludeGroup) {
      url += `&excludeGroup=${excludeGroup}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UserList.decode(res.data).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static groupUserList(groupUID: string,
    pageNum: number,
    pageRow: number,
    searchText?: string,
    excludeUserUID?: string[],
    searchGroup?: boolean): Observable<Proto.IUser[]> {
    let url = `player/chat/group/${groupUID}/user?pageNum=${pageNum}&pageRow=${pageRow}`
    if (searchText) {
      url += `&text=${encodeURIComponent(searchText)}`
    }
    if (excludeUserUID) {
      url += excludeUserUID.map(uid => `&ex=${uid}`).join('')
    }
    if (searchGroup) {
      url += '&sg=1'
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UserList.decode(res.data).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static message(groupUID: string, chatId: string): Observable<Proto.IBoardChat> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${groupUID}/message/${chatId}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.BoardChat.decode(res.data)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static messageList(groupUID: string,
    pageRow: number,
    asc: boolean,
    pageToken?: string,
    searchText?: string,
    searchId?: string,
    types?: number[]): Observable<Proto.IBoardChatList> {
    let url = `player/chat/group/${groupUID}/message?pageRow=${pageRow}&asc=${asc ? 1 : 0}`
    if (pageToken) {
      url += `&pageToken=${encodeURIComponent(pageToken)}`
    }
    if (searchText) {
      url += `&search=${encodeURIComponent(searchText)}`
    }
    if (searchId) {
      url += `&searchId=${encodeURIComponent(searchId)}`
    }
    if (types) {
      url += types.map(t => `&t=${t}`).join('')
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.BoardChatList.decode(res.data)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static ogp(url: string): Observable<Proto.IOGPResponse> {
    let finalURL = url
    if (url.startsWith('//')) {
      finalURL = 'https:' + url
    } else if (url.startsWith('://')) {
      finalURL = 'https' + url
    } else if (!url.startsWith('http://') && !url.startsWith('https://')) {
      finalURL = 'https://' + url
    }
    if (ogpCache[finalURL]) {
      try {
        const cache = ogpCache[finalURL]
        const ogpData = Proto.OGPResponse.decode(cache, cache.length)
        if (ogpCacheKeys[finalURL]) {
          ogpCacheKeys[finalURL] += 1
        } else {
          ogpCacheKeys[finalURL] = 1
        }
        return of(ogpData)
      } catch (e) {
        delete ogpCache[finalURL]
        delete ogpCacheKeys[finalURL]
      }
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/ogp?url=${encodeURIComponent(finalURL)}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            const data = Proto.OGPResponse.decode(res.data, res.data.length)
            if (Object.keys(ogpCache).length > 100) {
              const sorted = Object.keys(ogpCacheKeys).sort((key1, key2) => ogpCacheKeys[key1] - ogpCacheKeys[key2])
              if (sorted.length > 0) {
                delete ogpCacheKeys[sorted[0]]
                delete ogpCache[sorted[0]]
              }
            }
            ogpCacheKeys[finalURL] = 0
            ogpCache[finalURL] = Proto.OGPResponse.encode(data).finish()
            return data
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static findFileURL(groupUID: string, chatId: string, fileUID: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${groupUID}/message/${chatId}/file/${fileUID}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return util.utf8.read(res.data, 0, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static boardTopicGroupChatUrl(
    boardUID: string,
    groupUID: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/group/${groupUID}/chat`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return util.utf8.read(res.data, 0, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch(e => {
          if ((e as MessageError).code === ErrorCode.NoContent) {
            return Promise.reject(MessageError.from(ErrorCode.GroupChatNoJoined, e))
          }
          return Promise.reject(e)
        })
    )
  }

  public static joinBoardGroup(
    boardUID: string,
    groupUID: string,
    password?: string): Observable<void> {
    let url = `player/community/${boardUID}/group/${groupUID}/join`
    if (password) {
      url += `?pw=${encodeURIComponent(password)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'POST'
        })
        .then(res => {
        })
        .catch(e => {
          if ((e as MessageError).code === ErrorCode.NoContent) {
            return Promise.reject(MessageError.from(ErrorCode.GroupChatPasswordInvalid, e))
          }
          return Promise.reject(e)
        })
    )
  }

  public static leaveBoardGroup(groupUID: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${groupUID}/leave`,
        {
          method: 'DELETE'
        })
        .then(res => {
        })
    )
  }

  public static boardGroupFCM(groupUID: string, on: boolean): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${groupUID}/fcm`,
        {
          method: on ? 'PUT' : 'DELETE'
        })
        .then(res => {
        })
    )
  }

  public static boardGroupFixed(groupUID: string, on: boolean): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${groupUID}/fixed`,
        {
          method: on ? 'PUT' : 'DELETE'
        })
        .then(res => {
        })
    )
  }

  public static boardGroupMessageList(
    token: string,
    pageRow: number,
    asc: boolean,
    lastId?: string): Observable<Proto.IBoardChat[]> {
    let url = `socket/board/message?token=${token}&pageRow=${pageRow}&asc=${asc ? 1 : 0}`
    if (lastId) {
      url += `&lastId=${encodeURIComponent(lastId)}`
    }
    return fromPromise(PlayerHttp.instance().requestAPIProto(url,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.BoardChatList.decode(res.data, res.data.length).value
        }
        throw MessageError.Unexpected
      }))
  }

  public static terms(type: number, subdomain: string): Observable<Proto.TermsVersionResponse> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/user/terms?type=${type}&d=${encodeURIComponent(subdomain)}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.TermsVersionResponse.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static insertTerms(type: number, version: number): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/user/terms/${version}?type=${type}`,
        {
          method: 'POST'
        })
        .then(res => {
        })
        .catch(e => {
          if ((e as MessageError).code === ErrorCode.NoContent) {
            return Promise.reject(MessageError.from(ErrorCode.GroupChatPasswordInvalid, e))
          }
          return Promise.reject(e)
        })
    )
  }

  public static chatUserDetail(token: string, chatId: string): Observable<Proto.ChatUserDetail> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`socket/user?token=${token}&id=${chatId}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.ChatUserDetail.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static recommendList(): Observable<RecommendList> {
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(PlayerApiService.domain())}/recommend-list`,
        {
          method: 'GET'
        })
        .then(res => {
          return res.data as RecommendList
        })
    )
  }

  // region: - funding

  public static fundingList(
    playlistUID: string,
    contentUID: string,
    pageNum: number,
    pageRow: number,
    isAdmin?: boolean): Observable<Proto.IFunding[]> {
    let url = `player/playlist/${playlistUID}/content/${contentUID}/funding?pageNum=${pageNum}&pageRow=${pageRow}`
    if (isAdmin) {
      url += '&admin=1'
    }
    return fromPromise(PlayerHttp.instance().requestAPIProto(url,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          const list = Proto.FundingList.decode(res.data, res.data.length).value
          for (const data of list) {
            const dataAny = (data as any)
            dataAny.soldout = true
            if (data.variationList) {
              const length = data.variationList.filter(variation => {
                const quantity = variation.quantity ?? -1
                return quantity !== 0
              }).length
              dataAny.soldout = length === 0

              const uidList = data.variationOrder ? data.variationOrder.split(',') : []
              let additional = data.variationList ?? []
              if (uidList.length > 0) {
                additional = additional.sort((a, b) => {
                  const index1 = uidList.indexOf(a.uid as string)
                  const index2 = uidList.indexOf(b.uid as string)
                  if (index1 >= 0) {
                    if (index2 >= 0) {
                      return index1 - index2
                    }
                    return -1
                  } else if (index2 >= 0) {
                    return 1
                  }
                  return -1
                })
              }
              data.variationList = additional
            }
          }
          return list
        }
        throw MessageError.Unexpected
      }))
  }

  public static fundingListCount(
    playlistUID: string,
    contentUID: string,
    isAdmin?: boolean): Observable<number> {
    let url = `player/playlist/${playlistUID}/content/${contentUID}/funding/count`
    if (isAdmin) {
      url += '?admin=1'
    }
    return fromPromise(PlayerHttp.instance().requestAPIProto(url,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return parseInt(util.utf8.read(res.data, 0, res.data.length), 10)
        }
        throw MessageError.Unexpected
      }))
  }

  public static getFunding(
    playlistUID: string,
    contentUID: string,
    fundingUID: string): Observable<Proto.IFunding> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/funding/${fundingUID}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          const data = Proto.Funding.decode(res.data, res.data.length)
          const dataAny = (data as any)
          dataAny.soldout = true
          if (data.variationList) {
            const length = data.variationList.filter(variation => {
              const quantity = variation.quantity ?? -1
              return quantity !== 0
            }).length
            dataAny.soldout = length === 0

            const uidList = data.variationOrder ? data.variationOrder.split(',') : []
            let additional = data.variationList ?? []
            if (uidList.length > 0) {
              additional = additional.sort((a, b) => {
                const index1 = uidList.indexOf(a.uid as string)
                const index2 = uidList.indexOf(b.uid as string)
                if (index1 >= 0) {
                  if (index2 >= 0) {
                    return index1 - index2
                  }
                  return -1
                } else if (index2 >= 0) {
                  return 1
                }
                return -1
              })
            }
            data.variationList = additional
          }
          return data
        }
        throw MessageError.Unexpected
      }))
  }

  public static getFundingOrder(
    playlistUID: string,
    contentUID: string,
    fundingUID: string,
    pageNum: number,
    pageRow: number): Observable<Proto.IFundingVariationOrder[]> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/funding/${fundingUID}/order?pageNum=${pageNum}&pageRow=${pageRow}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.FundingVariationOrderList.decode(res.data, res.data.length).value
        }
        throw MessageError.Unexpected
      }))
  }

  public static fundingAdditionalVideo(
    playlistUID: string,
    contentUID: string,
    fundingUID: string,
    additionalUID: string): Observable<Proto.IUploadFile> {
    let url = `player/playlist/${playlistUID}/content/${contentUID}/funding/${fundingUID}/video/${additionalUID}`
    if (app && app.$store && (app.$store.state as State).meta.isCustom) {
      url += `?d=${encodeURIComponent(window.location.hostname)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UploadFile.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  // endregion

  // region: - ticket
  public static ticketList(
    playlistUID: string,
    contentUID: string,
    pageNum: number,
    pageRow: number,
    isAdmin?: boolean): Observable<Proto.ITicket[]> {
    let url = `player/playlist/${playlistUID}/content/${contentUID}/ticket?pageNum=${pageNum}&pageRow=${pageRow}`
    if (isAdmin) {
      url += '&admin=1'
    }
    return fromPromise(PlayerHttp.instance().requestAPIProto(url,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          const list = Proto.TicketList.decode(res.data, res.data.length).value
          for (const data of list) {
            if (data.variationList) {
              const uidList = data.variationOrder ? data.variationOrder.split(',') : []
              let additional = data.variationList ?? []
              if (uidList.length > 0) {
                additional = additional.sort((a, b) => {
                  const index1 = uidList.indexOf(a.uid as string)
                  const index2 = uidList.indexOf(b.uid as string)
                  if (index1 >= 0) {
                    if (index2 >= 0) {
                      return index1 - index2
                    }
                    return -1
                  } else if (index2 >= 0) {
                    return 1
                  }
                  return -1
                })
              }
              data.variationList = additional
            }
          }
          return list
        }
        throw MessageError.Unexpected
      }))
  }

  public static ticketListCount(
    playlistUID: string,
    contentUID: string,
    isAdmin?: boolean): Observable<number> {
    let url = `player/playlist/${playlistUID}/content/${contentUID}/ticket/count`
    if (isAdmin) {
      url += '?admin=1'
    }
    return fromPromise(PlayerHttp.instance().requestAPIProto(url,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return parseInt(util.utf8.read(res.data, 0, res.data.length), 10)
        }
        throw MessageError.Unexpected
      }))
  }

  public static getTicket(
    playlistUID: string,
    contentUID: string,
    ticketUID: string): Observable<Proto.ITicket> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/ticket/${ticketUID}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          const data = Proto.Ticket.decode(res.data, res.data.length)
          if (data.variationList) {
            const uidList = data.variationOrder ? data.variationOrder.split(',') : []
            let additional = data.variationList ?? []
            if (uidList.length > 0) {
              additional = additional.sort((a, b) => {
                const index1 = uidList.indexOf(a.uid as string)
                const index2 = uidList.indexOf(b.uid as string)
                if (index1 >= 0) {
                  if (index2 >= 0) {
                    return index1 - index2
                  }
                  return -1
                } else if (index2 >= 0) {
                  return 1
                }
                return -1
              })
            }
            data.variationList = additional
          }
          return data
        }
        throw MessageError.Unexpected
      }))
  }

  public static getTicketOrder(
    playlistUID: string,
    contentUID: string,
    ticketUID: string,
    pageNum: number,
    pageRow: number): Observable<Proto.ITicketVariationScheduleOrder[]> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/ticket/${ticketUID}/order?pageNum=${pageNum}&pageRow=${pageRow}`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.TicketVariationScheduleOrderList.decode(res.data, res.data.length).value
        }
        throw MessageError.Unexpected
      }))
  }

  public static getTicketMainOrder(
    playlistUID: string,
    contentUID: string,
    ticketUID?: string): Observable<Proto.ITicketVariationScheduleOrder> {
    let url = `player/playlist/${playlistUID}/content/${contentUID}/ticket/order-main`
    if (ticketUID) {
      url += `?ticket=${ticketUID}`
    }
    return fromPromise(PlayerHttp.instance().requestAPIProto(url,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.TicketVariationScheduleOrder.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static ticketAdditionalVideo(
    playlistUID: string,
    contentUID: string,
    ticketUID: string,
    additionalUID: string): Observable<Proto.IUploadFile> {
    let url = `player/playlist/${playlistUID}/content/${contentUID}/ticket/${ticketUID}/video/${additionalUID}`
    if (app && app.$store && (app.$store.state as State).meta.isCustom) {
      url += `?d=${encodeURIComponent(window.location.hostname)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UploadFile.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static getTicketQRCode(orderUID: string): Observable<string> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/user/ticket/history/${orderUID}/qrcode`,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return util.utf8.read(res.data, 0, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static getTicketScheduleApplyCount(
    playlistUID: string,
    contentUID: string,
    ticketUID: string): Observable<number> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/ticket/${ticketUID}/schedule/count`, {
        method: 'GET'
      })
        .then(res => {
          if (res.data) {
            return parseInt(util.utf8.read(res.data, 0, res.data.length), 10)
          }
          return 0
        })
    )
  }

  public static getTicketScheduleListByDate(
    playlistUID: string,
    contentUID: string,
    ticketUID: string,
    variationUID: string,
    startDate: string,
    endDate: string,
    staffUID?: string): Observable<Proto.ITicketVariationSchedule[]> {
    let url = `player/playlist/${playlistUID}/content/${contentUID}/ticket/${ticketUID}/${variationUID}/schedule/date?s=${startDate}&e=${endDate}&tz=${new Date().getTimezoneOffset() * -60}`
    if (staffUID) {
      url += `&staff=${staffUID}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.TicketVariationScheduleList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static getTicketScheduleUserList(
    playlistUID: string,
    contentUID: string,
    ticketUID: string,
    variationUID: string,
    pageNum: number,
    pageRow: number,
    startDate?: string,
    endDate?: string,
    statusFilter?: number[]): Observable<Proto.ITicketVariationSchedule[]> {
    let url = `player/playlist/${playlistUID}/content/${contentUID}/ticket/${ticketUID}/${variationUID}/schedule/user?pageNum=${pageNum}&pageRow=${pageRow}&tz=${new Date().getTimezoneOffset() * -60}`
    if (startDate) {
      url += `&s=${startDate}`
    }
    if (endDate) {
      url += `&e=${endDate}`
    }
    if (statusFilter) {
      url += statusFilter.map(s => `&sf=${s}`).join('')
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.TicketVariationScheduleList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static getTicketVariationStaffList(
    playlistUID: string,
    contentUID: string,
    ticketUID: string,
    variationUID: string,
    pageNum: number,
    pageRow: number,
    search?: string): Observable<Proto.ITicketStaff[]> {
    let url = `player/playlist/${playlistUID}/content/${contentUID}/ticket/${ticketUID}/${variationUID}/staff?pageNum=${pageNum}&pageRow=${pageRow}&tz=${new Date().getTimezoneOffset() * -60}`
    if (search) {
      url += `&search=${encodeURIComponent(search)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.TicketStaffList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static checkTicketScheduleDuplicate(
    playlistUID: string,
    contentUID: string,
    ticketUID: string,
    variationUID: string,
    scheduleUID: string,
    startDate: string,
    staffUID?: string): Observable<void> {
    let url = `player/playlist/${playlistUID}/content/${contentUID}/ticket/${ticketUID}/${variationUID}/schedule-duplicate?schedule=${scheduleUID}&s=${startDate}`
    if (staffUID) {
      url += `&staff=${staffUID}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
        })
    )
  }

  public static addTicketSchedulePut(
    playlistUID: string,
    contentUID: string,
    ticketUID: string,
    variationUID: string,
    data: Proto.ITicketVariationScheduleDateUser): Observable<Proto.ITicketVariationScheduleDateUser> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/ticket/${ticketUID}/${variationUID}/schedule`,
        {
          method: 'PUT',
          data: Proto.TicketVariationScheduleDateUser.encode(data)
        })
        .then(res => {
          if (res.data) {
            return Proto.TicketVariationScheduleDateUser.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch(e => {
          if (e instanceof MessageError) {
            const code = (e as MessageError).code
            switch (code) {
              case ErrorCode.ScheduleMaxUserError:
                return Promise.reject(MessageError.from(ErrorCode.TicketQuantityFail, e))
              case ErrorCode.ScheduleDuplicatedError:
                return Promise.reject(MessageError.from(ErrorCode.TicketDuplicatedError, e))
            }
          }
          return Promise.reject(e)
        })
    )
  }

  public static cancelTicketPayment(dateUserUID: string): Observable<any> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/user/ticket/history/by-date/${dateUserUID}?tz=${new Date().getTimezoneOffset() * -60}`, {
      method: 'DELETE'
    } as AxiosRequestConfig)
      .then(res => {
        if (!res) {
          throw MessageError.from(ErrorCode.NoTicketProduct)
        }
        return res.data as any
      })
      .catch(e => {
        if (e instanceof MessageError) {
          const code = (e as MessageError).code
          switch (code) {
            case ErrorCode.PaymentRefundFail:
              return Promise.reject(MessageError.from(ErrorCode.TicketRefundFail, e))
            case ErrorCode.PaymentRefundDeliveredFail:
              return Promise.reject(MessageError.from(ErrorCode.TicketRefundDeliveredFail, e))
            case ErrorCode.PaymentRefundFixedError:
              return Promise.reject(MessageError.from(ErrorCode.TicketRefundFixedError, e))
          }
        }
        return Promise.reject(e)
      }))
  }

  // endregion

  // region: - third oauth
  public static thirdOAuthLogin(
    oauthType: number,
    query: string): Observable<Proto.ThirdOAuthResponse> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`auth/third-oauth/authenticate/${oauthType}?d=${PlayerApiService.domain()}&${query}`, {
        method: 'POST'
      })
        .then(res => {
          if (res.data) {
            return Proto.ThirdOAuthResponse.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerLoginNeed) {
            e = MessageError.from(ErrorCode.ServerLoginFail, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static thirdOAuthSync(
    oauthType: number,
    tmpUID: string,
    query: string): Observable<Proto.ThirdOAuthResponse> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`auth/third-oauth/sync/${oauthType}?d=${PlayerApiService.domain()}&tmp=${encodeURIComponent(tmpUID)}&${query}`, {
        method: 'POST'
      })
        .then(res => {
          if (res.data) {
            return Proto.ThirdOAuthResponse.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerLoginNeed) {
            e = MessageError.from(ErrorCode.ServerLoginFail, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static thirdOAuthSyncUserInfo(oauthType: number): Observable<Proto.ThirdOAuthResponse> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`auth/third-oauth/sync-user-info/${oauthType}?d=${PlayerApiService.domain()}`, {
        method: 'POST'
      })
        .then(res => {
          if (res.data) {
            return Proto.ThirdOAuthResponse.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerLoginNeed) {
            e = MessageError.from(ErrorCode.ServerLoginFail, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static thirdOAuthChangePasswordURL(oauthType: number): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(`auth/third-oauth/change-pw/${oauthType}?d=${PlayerApiService.domain()}`, {
        method: 'GET'
      })
        .then(res => {
          return res.data as string
        })
    )
  }

  public static thirdOAuthForgotPasswordURL(oauthType: number): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(`auth/third-oauth/forgot-pw/${oauthType}?d=${PlayerApiService.domain()}`, {
        method: 'GET'
      })
        .then(res => {
          return res.data as string
        })
    )
  }

  public static thirdOAuthMyPageURL(oauthType: number): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(`auth/third-oauth/mypage/${oauthType}?d=${PlayerApiService.domain()}`, {
        method: 'GET'
      })
        .then(res => {
          return res.data as string
        })
    )
  }

  public static thirdOAuthRegisterURL(oauthType: number): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIJson(`auth/third-oauth/register/${oauthType}?d=${PlayerApiService.domain()}`, {
        method: 'GET'
      })
        .then(res => {
          return res.data as string
        })
    )
  }

  // endregion

  public static checkJoin(): Observable<boolean> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/user/service/join?d=${encodeURIComponent(PlayerApiService.domain())}`,
        {
          method: 'GET'
        })
        .then(res => {
          return !!res.data
        })
        .catch(e => {
          if (e.code === ErrorCode.NoContent) {
            return false
          }
          return Promise.reject(e)
        })
    )
  }

  // region: - dm

  public static dmList(pageNum: number, pageRow: number, companyUID?: string): Observable<Proto.IAppMessage[]> {
    let url = `player/user/chat/list?pageNum=${pageNum}&pageRow=${pageRow}`
    if (companyUID) {
      url += `&cp=${companyUID}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.AppMessageList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  // public static dmAdminList(pageNum: number, pageRow: number): Observable<Proto.IAppMessage[]> {
  //   return fromPromise(
  //     PlayerHttp.instance().requestAPIProto(`player/user/chat/list/admin?pageNum=${pageNum}&pageRow=${pageRow}`,
  //       {
  //         method: 'GET'
  //       })
  //       .then(res => {
  //         if (res.data) {
  //           return Proto.AppMessageList.decode(res.data, res.data.length).value
  //         }
  //         throw MessageError.Unexpected
  //       })
  //   )
  // }
  public static dmAdminList(companyUID: string,
    pageNum: number,
    pageRow: number,
    search?: string): Observable<Proto.IBoardChatRoom[]> {
    let url = `player/chat/dm-room/${companyUID}/admin/list?pageNum=${pageNum}&pageRow=${pageRow}`
    if (search) {
      url += `&text=${encodeURIComponent(search)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.BoardChatRoomList.decode(res.data).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static dmMessageList(
    companyUID: string,
    pageNum: number,
    pageRow: number): Observable<Proto.IAppMessageList> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/user/chat/${companyUID}/list?pageNum=${pageNum}&pageRow=${pageRow}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.AppMessageList.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static dmAdminMessageList(
    companyUID: string,
    userUID: string,
    pageNum: number,
    pageRow: number): Observable<Proto.IAppMessageList> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/user/chat/${companyUID}/list/admin/${userUID}?pageNum=${pageNum}&pageRow=${pageRow}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.AppMessageList.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  // public static dmSendMessage(companyUID: string, data: Proto.IAppMessage): Observable<Proto.IAppMessage> {
  //   return fromPromise(
  //     PlayerHttp.instance().requestAPIProto(`player/user/chat/${companyUID}/message`,
  //       {
  //         method: 'POST',
  //         data: Proto.AppMessage.encode(data)
  //       })
  //       .then(res => {
  //         if (res.data) {
  //           return Proto.AppMessage.decode(res.data, res.data.length)
  //         }
  //         throw MessageError.Unexpected
  //       })
  //   )
  // }

  public static deleteMessage(groupUID: string, chatId: string): Observable<void> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${groupUID}/message/${chatId}`,
        {
          method: 'DELETE'
        })
        .then(res => {
        })
    )
  }

  public static transferMessage(groupUID: string, chatId: string, toGroupUID: string): Observable<Proto.IBoardChat> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${groupUID}/message/${chatId}/transfer?to=${toGroupUID}`,
        {
          method: 'POST'
        })
        .then(res => {
          if (res.data) {
            return Proto.BoardChat.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            e = MessageError.from(ErrorCode.NoChat, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static dmSendMessage(groupUID: string, data: Proto.IBoardChat): Observable<Proto.IBoardChat> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${groupUID}/message`,
        {
          method: 'POST',
          data: Proto.BoardChat.encode(data)
        })
        .then(res => {
          if (res.data) {
            return Proto.BoardChat.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static sendFile(groupUID: string,
    data: Proto.IBoardChat,
    files: [File, HTMLImageElement | null][]): Observable<ProgressData<Proto.IBoardChat>> {
    return new Observable<ProgressData<Proto.IBoardChat>>(subscriber => {
      PlayerHttp.instance().requestAPIProto(`player/chat/group/${groupUID}/message`,
        {
          method: 'POST',
          data: Proto.BoardChat.encode(data)
        })
        .then(res => {
          if (res.data) {
            return Proto.BoardChat.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .then(output => {
          const uploadList: Promise<[string, string | null]>[] = []
          if (output.fileList && output.fileList.length && files.length) {
            const allFileSize = files.map(f => f[0]?.size ?? 0).reduce((a, b) => a + b, 0)
            for (let i = 0; i < files.length; ++i) {
              const fileDTO = output.fileList[i]
              const fileType = files[i][0].type?.replace('image/jpg', 'image/jpeg') || ''
              let resizedFile: File | null = null
              if (files[i][1]) {
                const thumbnail = ImageService.resizeImage(files[i][1] as HTMLImageElement, 640, 'image/jpeg')
                resizedFile = thumbnail ? ImageService.decodeDataURIToFile(thumbnail) : files[i][0]
              }
              const fileSize = files[i][0]?.size ?? 0
              const fileSizeAlpha = fileSize > 0 ? (fileSize / allFileSize) : 0
              let uploadProgress = 0
              const promises: Promise<AxiosResponse>[] = [axios.put(fileDTO.path as string, files[i][0],
                {
                  headers: { 'Content-Type': fileType },
                  onUploadProgress: (e) => {
                    const percentage = e.loaded / e.total
                    const alpha = percentage - uploadProgress
                    uploadProgress = percentage
                    subscriber.next({
                      progress: alpha * fileSizeAlpha,
                      end: true
                    })
                  }
                })]
              if (resizedFile) {
                promises.push(axios.put(fileDTO.thumbnailPath as string, resizedFile,
                  {
                    headers: { 'Content-Type': 'image/jpeg' }
                  }))
              }
              uploadList.push(Promise.all(promises)
                .then((result) => {
                  const success = result.filter(res => res.status === 200).length === result.length
                  if (!success) {
                    throw MessageError.from(ErrorCode.FileUpload)
                  }
                  fileDTO.uploaded = {
                    has: true,
                    value: true
                  }
                  if (resizedFile) {
                    fileDTO.thumbnailUploaded = {
                      has: true,
                      value: true
                    }
                  } else {
                    fileDTO.thumbnailUploaded = {
                      has: true,
                      value: false
                    }
                  }
                  return [fileDTO.uid as string, resizedFile ? fileDTO.uid as string : null]
                }))
            }
          }
          if (!uploadList.length) {
            subscriber.next({
              progress: 1,
              end: true,
              response: output
            })
            subscriber.complete()
            return
          }
          return Promise.all(uploadList)
            .then(uploadUIDList => {
              const req = Proto.ContentConvertRequest.create({
                uid: uploadUIDList.map(uid => uid[0]).filter(uid => !!uid),
                thumbnailUID: uploadUIDList.map(uid => uid[1] as string).filter(uid => !!uid)
              })
              return PlayerHttp.instance().requestAPIProto(`player/chat/group/${groupUID}/message/${output.id}/convert`,
                {
                  method: 'POST',
                  data: Proto.ContentConvertRequest.encode(req)
                })
            })
            .then(_ => {
              subscriber.next({
                progress: 1,
                end: true,
                response: output
              })
              subscriber.complete()
            })
        })
        .catch(e => subscriber.error(e))
    })
  }

  // endregion

  public static openDmUser(uid: string, target: number): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/chat/group/dm-user?uid=${uid}&target=${target}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.CommunityBoardGroup.decode(res.data, res.data.length).uid
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerLoginNeed) {
            e = MessageError.from(ErrorCode.ServerLoginFail, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static profilePlanList(
    boardUID: string,
    userUID: string,
    pageNum: number,
    pageRow: number): Observable<Proto.IMemberId[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/profile/${userUID}/plan-list?pageNum=${pageNum}&pageRow=${pageRow}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.MemberIdList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static profileGroupList(
    boardUID: string,
    userUID: string,
    pageNum: number,
    pageRow: number): Observable<Proto.IMemberId[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/community/${boardUID}/profile/${userUID}/group-list?pageNum=${pageNum}&pageRow=${pageRow}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.MemberIdList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static userGroupList(
    companyUID: string,
    req: Proto.IListRequestFromApp): Observable<Proto.ICompanyUserGroup[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/company/${companyUID}/app/user-group`,
        {
          method: 'POST',
          data: Proto.ListRequestFromApp.encode(req)
        })
        .then(res => {
          if (res.data) {
            return Proto.CompanyUserGroupList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static userGroup(groupUID: string): Observable<Proto.ICompanyUserGroup> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/user-group/${groupUID}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.CompanyUserGroup.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            e = MessageError.from(ErrorCode.NoUserGroup, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static userGroupUserList(
    groupUID: string,
    pageNum: number,
    pageRow: number): Observable<Proto.IUser[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/user-group/${groupUID}/user?pageNum=${pageNum}&pageRow=${pageRow}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UserList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            e = MessageError.from(ErrorCode.NoUserGroup, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static appFeatures(): Observable<any> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(PlayerApiService.domain())}/app-features`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      }))
  }

  public static appFeaturesTypeList(): Observable<number[]> {
    if (!PlayerApiService.domain()) {
      return of([])
    }
    return this.appFeatures()
      .pipe(map(res => {
        return res?.typeList ?? []
      }))
  }

  public static newImage(file: File, progressBlock?: (progress: number) => void): Observable<string> {
    const extension = file.name?.split('.')?.pop()?.toLowerCase() || 'jpg'
    const imageType = 'image/jpeg'
    return ImageService.imageFileToDataURL(file)
      .pipe(
        mergeMap(url => ImageService.loadImage(url)),
        map(image => {
          if (Math.min(image.width, image.height) < 600) {
            return file
          }
          const thumbnail = ImageService.resizeImage(image, 600, imageType)
          if (thumbnail) {
            return ImageService.decodeDataURIToFile(thumbnail)
          } else {
            return file
          }
        }),
        mergeMap(image =>
          Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(PlayerApiService.domain())}/image?ext=${encodeURIComponent(extension)}`, {
            method: 'POST'
          } as AxiosRequestConfig)
            .catch(e => {
              if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerTrafficExceed) {
                return Promise.reject(MessageError.from(ErrorCode.ServerStorageExceed))
              }
              return Promise.reject(e)
            })
            .then(res => res.data as any)
            .then(dto =>
              axios({
                url: dto.thumbnailPath,
                method: 'PUT',
                data: image,
                headers: {
                  'Content-Type': file.type
                },
                onUploadProgress: (progressEvent) => {
                  if (progressEvent.total) {
                    const progress = progressEvent.loaded / progressEvent.total
                    if (progressBlock) {
                      progressBlock(progress)
                    }
                  }
                }
              })
                .then(res => dto.fileURL)
            )
            .catch(e => {
              if (e.isAxiosError && !e.response) {
                return Promise.reject(MessageError.from(ErrorCode.NoInternet, e))
              }
              return Promise.reject(MessageError.from(ErrorCode.FileDownload, e))
            })
        )
      )
  }

  public static addLogContentAccess(playlistUID: string, contentUID: string): Promise<void> {
    return PlayerHttp.instance().requestAPIProto(`player/stat/log/content-access?puid=${playlistUID}&cuid=${contentUID}`,
      {
        method: 'POST'
      })
      .then(res => {
      })
  }

  public static addLogTopicAccess(forumUID: string, topicUID: string): Promise<void> {
    return PlayerHttp.instance().requestAPIProto(`player/stat/log/topic-access?fuid=${forumUID}&tuid=${topicUID}`,
      {
        method: 'POST'
      })
      .then(res => {
      })
  }

  public static addLogUserAccess(): Promise<void> {
    const boardData = store.state.meta.board
    if (boardData && boardData.companyUID) {
      return PlayerHttp.instance().requestAPIProto(`player/stat/log/user-access?companyUID=${boardData.companyUID}`,
        {
          method: 'POST'
        })
        .then(res => {
        })
    }
    return Promise.resolve()
  }

  public static addLogFileAccess(action: number,
    metaType: number,
    metaUID: string,
    fileUID: string[],
    filename?: string[],
    token?: string,
    userUID?: string): Promise<void> {
    const boardData = store.state.meta.board
    if (boardData && boardData.companyUID) {
      let url = `player/stat/log/file-access?companyUID=${boardData.companyUID}&action=${action}&meta=${metaType}&metaUID=${metaUID}&${fileUID.map(f => `fileUID=${f}`).join('&')}`
      if (filename && filename.length) {
        url += `&${filename.map(n => `filename=${encodeURIComponent(n)}`).join('&')}`
      }
      if (token) {
        url += `&token=${encodeURIComponent(token)}`
      }
      if (userUID) {
        url += `&userUID=${userUID}`
      }
      return PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'POST'
        })
        .then(res => {
        })
    }
    return Promise.resolve()
  }

  public static findAppSetting(companyUID: string): Observable<Proto.ICompanyAppSetting> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/user/company/${companyUID}/setting`, {
      method: 'GET'
    })
      .then(res => {
        if (res.data) {
          return Proto.CompanyAppSetting.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      }))
  }

  public static getWorkflowBuilder(builderUID: string,
    ignoreCheck?: boolean,
    fromAnswerUID?: string): Observable<Proto.IWorkflowBuilder> {
    let url = `player/workflow/${builderUID}?`
    if (ignoreCheck) {
      url += '&ic=1'
    }
    if (fromAnswerUID) {
      url += `&fromAnswer=${fromAnswerUID}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkflowBuilder.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            e = MessageError.from(ErrorCode.NoWorkflow, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static insertWorkflowAnswer(builderUID: string,
    answer: Proto.IWorkflowBuilderAnswer): Observable<Proto.IWorkflowBuilderAnswer> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/workflow/${builderUID}/answer`,
        {
          method: 'POST',
          data: Proto.WorkflowBuilderAnswer.encode(answer)
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkflowBuilderAnswer.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            e = MessageError.from(ErrorCode.NoWorkflow, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static insertWorkflowAnswerList(builderUID: string,
    answer: Proto.IWorkflowBuilderAnswer[]): Observable<Proto.IWorkflowBuilderAnswer[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/workflow/${builderUID}/answer/list`,
        {
          method: 'POST',
          data: Proto.WorkflowBuilderAnswerList.encode({ value: answer })
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkflowBuilderAnswerList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            e = MessageError.from(ErrorCode.NoWorkflow, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static updateWorkflowAnswerStatus(answerUID: string,
    statusUID: string,
    text?: string): Observable<Proto.IWorkflowBuilderAnswer> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/workflow/answer/${answerUID}/status?status=${statusUID}` + (text ? `&text=${encodeURIComponent(text)}` : ''),
        {
          method: 'PUT'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkflowBuilderAnswer.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            e = MessageError.from(ErrorCode.NoWorkflow, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static revertWorkflowAnswerStatus(answerUID: string,
    notify: boolean,
    text?: string): Observable<Proto.IWorkflowBuilderAnswer> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/workflow/answer/${answerUID}/status/revert?notify=${notify}` + (text ? `&text=${encodeURIComponent(text)}` : ''),
        {
          method: 'PUT'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkflowBuilderAnswer.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            e = MessageError.from(ErrorCode.NoWorkflow, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static workflowAnsweredBuilderList(
    pageNum: number,
    pageRow: number,
    companyUID?: string,
    uidList?: string[]): Observable<Proto.IWorkflowBuilder[]> {
    let url = `player/workflow/builder?pageNum=${pageNum}&pageRow=${pageRow}` + (companyUID ? `&cuid=${companyUID}` : '')
    if (uidList && uidList.length) {
      url += uidList.map(uid => `&uid=${uid}`).join('')
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkflowBuilderList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            return []
          }
          throw e
        })
    )
  }

  public static workflowAnsweredUserList(
    pageNum: number,
    pageRow: number,
    companyUID: string,
    builderUIDList?: string[]): Observable<Proto.IUser[]> {
    let url = `player/workflow/user?pageNum=${pageNum}&pageRow=${pageRow}&cuid=${companyUID}`
    if (builderUIDList && builderUIDList.length) {
      url += builderUIDList.map(uid => `&build=${uid}`).join('')
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UserList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            return []
          }
          throw e
        })
    )
  }

  public static workflowAnswerList(
    companyUID: string,
    pageNum: number,
    pageRow: number,
    options?: {
      builderUID?: string[];
      searchType?: number;
      searchTitle?: string;
      sortType?: number;
      sortASC?: boolean;
      statusUIDList?: string[];
      includeFile?: boolean;
      includeInfo?: boolean;
      userUIDList?: string[];
      registerDate?: [Date, Date];
    }): Observable<Proto.IWorkflowBuilderAnswer[]> {
    let url = `player/workflow/answer?pageNum=${pageNum}&pageRow=${pageRow}&cp=${companyUID}`
    if (options && options.builderUID && options.builderUID.length) {
      url += options.builderUID.map(u => `&builderUID=${u}`).join('')
    }
    if (options && options.searchTitle) {
      url += `&search=${encodeURIComponent(options.searchTitle)}`
      if (options.searchType) {
        url += `&searchType=${options.searchType}`
      }
    }
    if (options?.sortType) {
      url += `&sortType=${options.sortType}`
    }
    if (options?.sortASC !== undefined) {
      url += `&sort=${options.sortASC ? 1 : 0}`
    }
    if (options && options.statusUIDList && options.statusUIDList.length) {
      url += options.statusUIDList.map(u => `&status=${u}`).join('')
    }
    if (options?.includeFile) {
      url += '&if=1'
    }
    if (options?.includeInfo) {
      url += '&info=1'
    }
    if (options && options.userUIDList && options.userUIDList.length) {
      url += options.userUIDList.map(uid => `&user=${uid}`).join('')
    }
    if (options && options.registerDate) {
      url += `&registerDate=${moment(options.registerDate[0]).utc().format('YYYY-MM-DDTHH:mm')}_${moment(options.registerDate[1]).add(1, 'd').utc().format('YYYY-MM-DDTHH:mm')}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkflowBuilderAnswerList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            return []
          }
          throw e
        })
    )
  }

  public static workflowAnswerListCSV(
    options?: {
      builderUID?: string[];
      searchType?: number;
      searchTitle?: string;
      sortType?: number;
      sortASC?: boolean;
      statusUIDList?: string[];
      includeInfo?: boolean;
      userUIDList?: string[];
      uidList?: string[];
      registerDate?: [Date, Date];
    }): Observable<Blob> {
    let url = 'player/workflow/answer/csv?'
    if (options && options.builderUID && options.builderUID.length) {
      url += options.builderUID.map(u => `&builderUID=${u}`).join('')
    }
    if (options && options.searchTitle) {
      url += `&search=${encodeURIComponent(options.searchTitle)}`
      if (options.searchType) {
        url += `&searchType=${options.searchType}`
      }
    }
    if (options?.sortType) {
      url += `&sortType=${options.sortType}`
    }
    if (options?.sortASC !== undefined) {
      url += `&sort=${options.sortASC ? 1 : 0}`
    }
    if (options && options.statusUIDList && options.statusUIDList.length) {
      url += options.statusUIDList.map(u => `&status=${u}`).join('')
    }
    if (options?.includeInfo) {
      url += '&info=1'
    }
    if (options && options.userUIDList && options.userUIDList.length) {
      url += options.userUIDList.map(uid => `&user=${uid}`).join('')
    }
    if (options && options.registerDate) {
      url += `&registerDate=${moment(options.registerDate[0]).utc().format('YYYY-MM-DDTHH:mm')}_${moment(options.registerDate[1]).add(1, 'd').utc().format('YYYY-MM-DDTHH:mm')}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPI<Blob>(url, {
        method: 'POST',
        data: Proto.WorkflowBuilderAnswerList.encode({ value: (options?.uidList ?? []).map(uid => ({ uid })) }),
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/x-protobuf'
        }
      })
        .then(res => {
          let loginError = false
          if (res.status === 401 || res.status === 403) {
            loginError = true
          }
          if (loginError) {
            return Promise.reject(MessageError.from(ErrorCode.ServerLoginNeed))
          } else if (res.status !== 200) {
            return Promise.reject(MessageError.from(ErrorCode.ServerInternal, new Error(res.status + ' : ' + res.statusText)))
          }
          return res.data
        })
    )
  }

  public static workflowAnswer(answerUID: string): Observable<Proto.IWorkflowBuilderAnswer> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/workflow/answer/${answerUID}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkflowBuilderAnswer.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            e = MessageError.from(ErrorCode.NoWorkflow, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static workflowAnswerFileTemplateList(answerUID: string,
    uploaded?: boolean): Observable<Proto.IWorkflowFileTemplate[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/workflow/answer/${answerUID}/file-template` + (uploaded !== undefined ? `?uploaded=${uploaded}` : ''),
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkflowFileTemplateList.decode(res.data, res.data.length).value ?? []
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            e = MessageError.from(ErrorCode.NoWorkflow, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static workflowAnswerFileTemplatePDFEditorURL(answerUID: string): Observable<string> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/workflow/answer/${answerUID}/pdf-editor-url`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return util.utf8.read(res.data, 0, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            e = MessageError.from(ErrorCode.NoWorkflow, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static saveWorkflowAnswerFileDraft(answerUID: string,
    templateUID: string,
    answer: Proto.IWorkflowBuilderAnswerFile): Observable<Proto.IWorkflowBuilderAnswerFile> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/workflow/answer/${answerUID}/file/draft?templateUID=${templateUID}`,
        {
          method: 'PUT',
          data: Proto.WorkflowBuilderAnswerFile.encode(answer)
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkflowBuilderAnswerFile.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            e = MessageError.from(ErrorCode.NoWorkflow, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static insertWorkflowAnswerFile(answerUID: string,
    templateUID: string,
    fileData: Proto.IUploadFile,
    file: File,
    image?: HTMLImageElement | null): Observable<ProgressResult<Proto.IWorkflowBuilderAnswerFile>> {
    return new Observable<ProgressResult<Proto.IWorkflowBuilderAnswerFile>>(subscriber => {
      PlayerHttp.instance().requestAPIProto(`player/workflow/answer/${answerUID}/file?templateUID=${templateUID}`,
        {
          method: 'PUT',
          data: Proto.UploadFile.encode(fileData)
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkflowBuilderAnswerFile.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .then(data => {
          if (!data.file) {
            return Promise.resolve(data)
          }
          const fileType = file.type?.replace('image/jpg', 'image/jpeg') || ''
          let resizedFile: File | null = null
          if (image) {
            const resized = ImageService.resizeImage(image, 600, 'image/jpeg')
            if (resized) {
              resizedFile = ImageService.decodeDataURIToFile(resized)
            }
          }
          const promises: Promise<AxiosResponse>[] = [axios.put(data.file.path as string, file,
            {
              headers: { 'Content-Type': fileType },
              onUploadProgress: (e) => {
                const percentage = e.loaded / e.total
                subscriber.next({
                  progress: percentage
                })
              }
            })]
          if (resizedFile) {
            promises.push(axios.put(data.file.thumbnailPath as string, resizedFile,
              {
                headers: { 'Content-Type': 'image/jpeg' }
              }))
          }
          const fileDTO = data.file
          return Promise.all(promises)
            .then((result): [string, string | null] => {
              const success = result.filter(res => res.status === 200).length === result.length
              if (!success) {
                throw MessageError.from(ErrorCode.FileUpload)
              }
              return [fileDTO.uid as string, resizedFile ? fileDTO.uid as string : null]
            })
            .then(uidList => {
              const req = Proto.ContentConvertRequest.create({
                uid: [uidList[0]],
                thumbnailUID: uidList[1] !== null ? [uidList[1]] : null
              })
              return PlayerHttp.instance().requestAPIProto(`player/workflow/answer/${answerUID}/file/convert`,
                {
                  method: 'POST',
                  data: Proto.ContentConvertRequest.encode(req)
                })
            })
            .then(() => data) as Promise<Proto.WorkflowBuilderAnswerFile>
        })
        .then(data => {
          subscriber.next({
            data
          })
          subscriber.complete()
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            e = MessageError.from(ErrorCode.NoWorkflow, (e as MessageError).error)
          }
          subscriber.error(e)
        })
    })
  }

  public static workflowAnswerFile(answerUID: string, fileUID: string): Observable<Proto.IWorkflowBuilderAnswerFile> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/workflow/answer/${answerUID}/file/${fileUID}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkflowBuilderAnswerFile.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            e = MessageError.from(ErrorCode.NoWorkflow, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static workflowParams(
    builderUID: string,
    formUID: string,
    fromAnswerUID?: string): Observable<Proto.IWorkflowParams> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/workflow/builder/${builderUID}/form/${formUID}/params` + (fromAnswerUID ? `?fromAnswer=${fromAnswerUID}` : ''),
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkflowParams.decode(res.data, res.data.length)
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            e = MessageError.from(ErrorCode.NoWorkflow, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static workflowParamsAnswerList(
    builderUID: string,
    formUID: string,
    pageNum: number,
    pageRow: number,
    options?: {
      search?: [string, string];
      fromAnswerUID?: string;
      sortUID?: string;
      sortASC?: boolean;
    }): Observable<Proto.IWorkflowParamsAnswer[]> {
    let url = `player/workflow/builder/${builderUID}/form/${formUID}/data?pageNum=${pageNum}&pageRow=${pageRow}`
    if (options && options.search !== undefined) {
      url += `&searchUID=${options.search[0]}&search=${encodeURIComponent(options.search[1])}`
    }
    if (options && options.fromAnswerUID) {
      url += `&fromAnswer=${options.fromAnswerUID}`
    }
    if (options && options.sortUID) {
      url += `&sortUID=${options.sortUID}`
      if (options.sortASC !== undefined) {
        url += `&sort=${options.sortASC}`
      }
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkflowParamsAnswerList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            return []
          }
          throw e
        })
    )
  }

  public static workflowParamsAnswerListCount(
    builderUID: string,
    formUID: string,
    search?: [string, string],
    fromAnswerUID?: string): Observable<number> {
    let url = `player/workflow/builder/${builderUID}/form/${formUID}/data/count?`
    if (search !== undefined) {
      url += `&searchUID=${search[0]}&search=${encodeURIComponent(search[1])}`
    }
    if (fromAnswerUID) {
      url += `&fromAnswer=${fromAnswerUID}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return parseInt(util.utf8.read(res.data, 0, res.data.length), 10)
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            return 0
          }
          throw e
        })
    )
  }

  public static workflowParamsConnectedData(
    builderUID: string,
    formUID: string,
    paramsUID: string,
    paramsAnswerUID: string): Observable<Proto.IWorkflowParamsConnectData[]> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/workflow/builder/${builderUID}/form/${formUID}/params/${paramsUID}/${paramsAnswerUID}`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkflowParamsConnectDataList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            e = MessageError.from(ErrorCode.NoWorkflow, (e as MessageError).error)
          }
          throw e
        })
    )
  }

  public static workflowAnswerHistory(
    answerUID: string,
    pageNum: number,
    pageRow: number,
    sortASC?: boolean): Observable<Proto.IWorkflowBuilderAnswerHistory[]> {
    let url = `player/workflow/answer/${answerUID}/history?pageNum=${pageNum}&pageRow=${pageRow}`
    if (sortASC !== undefined) {
      url += `&sortASC=${sortASC ? 1 : 0}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkflowBuilderAnswerHistoryList.decode(res.data, res.data.length).value
          }
          throw MessageError.Unexpected
        })
        .catch((e) => {
          if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
            return []
          }
          throw e
        })
    )
  }

  public static contentWorkflowList(
    playlistUID: string,
    contentUID: string,
    pageNum: number,
    pageRow: number,
    isAdmin?: boolean): Observable<Proto.IWorkflowBuilder[]> {
    let url = `player/playlist/${playlistUID}/content/${contentUID}/workflow?pageNum=${pageNum}&pageRow=${pageRow}`
    if (isAdmin) {
      url += '&admin=1'
    }
    return fromPromise(PlayerHttp.instance().requestAPIProto(url,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.WorkflowBuilderList.decode(res.data, res.data.length).value
        }
        throw MessageError.Unexpected
      }))
  }

  public static contentWorkflowListCount(
    playlistUID: string,
    contentUID: string,
    isAdmin?: boolean): Observable<number> {
    let url = `player/playlist/${playlistUID}/content/${contentUID}/workflow/count`
    if (isAdmin) {
      url += '?admin=1'
    }
    return fromPromise(PlayerHttp.instance().requestAPIProto(url,
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return parseInt(util.utf8.read(res.data, 0, res.data.length), 10)
        }
        throw MessageError.Unexpected
      }))
  }

  public static contentWorkflowBuilder(
    playlistUID: string,
    contentUID: string,
    builderUID: string,
    fromAnswerUID?: string): Observable<Proto.IWorkflowBuilder> {
    return fromPromise(PlayerHttp.instance().requestAPIProto(`player/playlist/${playlistUID}/content/${contentUID}/workflow/${builderUID}` + (fromAnswerUID ? `?fromAnswer=${fromAnswerUID}` : ''),
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          return Proto.WorkflowBuilder.decode(res.data, res.data.length)
        }
        throw MessageError.Unexpected
      })
      .catch((e) => {
        if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
          e = MessageError.from(ErrorCode.NoWorkflow, (e as MessageError).error)
        }
        throw e
      })
    )
  }

  public static contentMentionUserList(playlistUID: string,
    contentUID: string,
    pageNum: number,
    pageRow: number,
    searchText: string): Observable<Proto.IUser[]> {
    const url = `player/playlist/${playlistUID}/content/${contentUID}/mention-user?pageNum=${pageNum}&pageRow=${pageRow}&search=${encodeURIComponent(searchText)}`
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UserList.decode(res.data).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static forumMentionUserList(boardUID: string,
    forumUID: string,
    pageNum: number,
    pageRow: number,
    searchText: string): Observable<Proto.IUser[]> {
    const url = `player/community/${boardUID}/forum/${forumUID}/mention-user?pageNum=${pageNum}&pageRow=${pageRow}&search=${encodeURIComponent(searchText)}`
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.UserList.decode(res.data).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static workStaffMe(companyUID: string): Observable<Proto.IWorkStaff> {
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(`player/work/${companyUID}/staff/me`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkStaff.decode(res.data)
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static workStaffTimetableClockList(companyUID: string,
    pageNum: number,
    pageRow: number,
    startDate?: string,
    endDate?: string,
    types?: number[],
    asc?: boolean): Observable<Proto.IWorkStoreTimetableClock[]> {
    let url = `player/work/${companyUID}/timetable-clock/list?pageNum=${pageNum}&pageRow=${pageRow}`
    if (startDate) {
      url += `&s=${encodeURIComponent(startDate)}`
    }
    if (endDate) {
      url += `&e=${encodeURIComponent(endDate)}`
    }
    if (types) {
      url += types.map(t => `&t=${t}`).join('')
    }
    if (asc !== undefined) {
      url += `&asc=${encodeURIComponent(asc)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkStoreTimetableClockList.decode(res.data).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static workStaffTimetableClockListCSV(companyUID: string,
    startDate?: string,
    endDate?: string,
    types?: number[],
    asc?: boolean): Observable<Blob> {
    let url = `player/work/${companyUID}/timetable-clock/list/csv?`
    if (startDate) {
      url += `&s=${encodeURIComponent(startDate)}`
    }
    if (endDate) {
      url += `&e=${encodeURIComponent(endDate)}`
    }
    if (types) {
      url += types.map(t => `&t=${t}`).join('')
    }
    if (asc !== undefined) {
      url += `&asc=${encodeURIComponent(asc)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPI<Blob>(url, {
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          let loginError = false
          if (res.status === 401 || res.status === 403) {
            loginError = true
          }
          if (loginError) {
            return Promise.reject(MessageError.from(ErrorCode.ServerLoginNeed))
          } else if (res.status !== 200) {
            return Promise.reject(MessageError.from(ErrorCode.ServerInternal, new Error(res.status + ' : ' + res.statusText)))
          }
          return res.data
        })
    )
  }

  public static workStaffTimetableApproveHistoryList(companyUID: string,
    pageNum: number,
    pageRow: number,
    startDate?: string,
    endDate?: string,
    asc?: boolean): Observable<Proto.IWorkStoreTimetableApproveHistory[]> {
    let url = `player/work/${companyUID}/timetable-approve-history/list?pageNum=${pageNum}&pageRow=${pageRow}`
    if (startDate) {
      url += `&s=${encodeURIComponent(startDate)}`
    }
    if (endDate) {
      url += `&e=${encodeURIComponent(endDate)}`
    }
    if (asc !== undefined) {
      url += `&asc=${encodeURIComponent(asc)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkStoreTimetableApproveHistoryList.decode(res.data).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static workStaffTimetableApproveHistoryListCSV(companyUID: string,
    startDate?: string,
    endDate?: string,
    asc?: boolean): Observable<Blob> {
    let url = `player/work/${companyUID}/timetable-approve-history/list/csv?`
    if (startDate) {
      url += `&s=${encodeURIComponent(startDate)}`
    }
    if (endDate) {
      url += `&e=${encodeURIComponent(endDate)}`
    }
    if (asc !== undefined) {
      url += `&asc=${encodeURIComponent(asc)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPI<Blob>(url, {
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          let loginError = false
          if (res.status === 401 || res.status === 403) {
            loginError = true
          }
          if (loginError) {
            return Promise.reject(MessageError.from(ErrorCode.ServerLoginNeed))
          } else if (res.status !== 200) {
            return Promise.reject(MessageError.from(ErrorCode.ServerInternal, new Error(res.status + ' : ' + res.statusText)))
          }
          return res.data
        })
    )
  }

  public static workStaffTimetableErrorHistoryList(companyUID: string,
    pageNum: number,
    pageRow: number,
    startDate?: string,
    endDate?: string,
    asc?: boolean): Observable<Proto.IWorkStoreTimetableErrorHistory[]> {
    let url = `player/work/${companyUID}/timetable-error-history/list?pageNum=${pageNum}&pageRow=${pageRow}`
    if (startDate) {
      url += `&s=${encodeURIComponent(startDate)}`
    }
    if (endDate) {
      url += `&e=${encodeURIComponent(endDate)}`
    }
    if (asc !== undefined) {
      url += `&asc=${encodeURIComponent(asc)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkStoreTimetableErrorHistoryList.decode(res.data).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static workStaffTimetableErrorHistoryListCSV(companyUID: string,
    startDate?: string,
    endDate?: string,
    asc?: boolean): Observable<Blob> {
    let url = `player/work/${companyUID}/timetable-error-history/list/csv?`
    if (startDate) {
      url += `&s=${encodeURIComponent(startDate)}`
    }
    if (endDate) {
      url += `&e=${encodeURIComponent(endDate)}`
    }
    if (asc !== undefined) {
      url += `&asc=${encodeURIComponent(asc)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPI<Blob>(url, {
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          let loginError = false
          if (res.status === 401 || res.status === 403) {
            loginError = true
          }
          if (loginError) {
            return Promise.reject(MessageError.from(ErrorCode.ServerLoginNeed))
          } else if (res.status !== 200) {
            return Promise.reject(MessageError.from(ErrorCode.ServerInternal, new Error(res.status + ' : ' + res.statusText)))
          }
          return res.data
        })
    )
  }

  public static workStaffTimetableApproveHistoryCountList(companyUID: string,
    pageNum: number,
    pageRow: number,
    startDate: string,
    endDate: string): Observable<Proto.IWorkStoreTimetableApproveHistoryCount[]> {
    let url = `player/work/${companyUID}/timetable-approve-history-count/list?pageNum=${pageNum}&pageRow=${pageRow}`
    if (startDate) {
      url += `&s=${encodeURIComponent(startDate)}`
    }
    if (endDate) {
      url += `&e=${encodeURIComponent(endDate)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkStoreTimetableApproveHistoryCountList.decode(res.data).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static workStaffTimetableApproveHistoryCountListCSV(companyUID: string,
    startDate: string,
    endDate: string): Observable<Blob> {
    let url = `player/work/${companyUID}/timetable-approve-history-count/list/csv?`
    if (startDate) {
      url += `&s=${encodeURIComponent(startDate)}`
    }
    if (endDate) {
      url += `&e=${encodeURIComponent(endDate)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPI<Blob>(url, {
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          let loginError = false
          if (res.status === 401 || res.status === 403) {
            loginError = true
          }
          if (loginError) {
            return Promise.reject(MessageError.from(ErrorCode.ServerLoginNeed))
          } else if (res.status !== 200) {
            return Promise.reject(MessageError.from(ErrorCode.ServerInternal, new Error(res.status + ' : ' + res.statusText)))
          }
          return res.data
        })
    )
  }

  public static workStaffSalaryMonthlyList(companyUID: string,
    pageNum: number,
    pageRow: number,
    startDate?: string,
    endDate?: string,
    asc?: boolean): Observable<Proto.IWorkStoreStaffSalaryMonth[]> {
    let url = `player/work/${companyUID}/staff-salary-month/list?pageNum=${pageNum}&pageRow=${pageRow}`
    if (startDate) {
      url += `&s=${encodeURIComponent(startDate)}`
    }
    if (endDate) {
      url += `&e=${encodeURIComponent(endDate)}`
    }
    if (asc !== undefined) {
      url += `&asc=${encodeURIComponent(asc)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPIProto(url,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return Proto.WorkStoreStaffSalaryMonthList.decode(res.data).value
          }
          throw MessageError.Unexpected
        })
    )
  }

  public static workStaffSalaryMonthlyListCSV(companyUID: string,
    startDate?: string,
    endDate?: string,
    asc?: boolean): Observable<Blob> {
    let url = `player/work/${companyUID}/staff-salary-month/list/csv?`
    if (startDate) {
      url += `&s=${encodeURIComponent(startDate)}`
    }
    if (endDate) {
      url += `&e=${encodeURIComponent(endDate)}`
    }
    if (asc !== undefined) {
      url += `&asc=${encodeURIComponent(asc)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPI<Blob>(url, {
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          let loginError = false
          if (res.status === 401 || res.status === 403) {
            loginError = true
          }
          if (loginError) {
            return Promise.reject(MessageError.from(ErrorCode.ServerLoginNeed))
          } else if (res.status !== 200) {
            return Promise.reject(MessageError.from(ErrorCode.ServerInternal, new Error(res.status + ' : ' + res.statusText)))
          }
          return res.data
        })
    )
  }

  public static workStaffSalaryMonthlyOvertimeListCSV(companyUID: string,
    startDate?: string,
    endDate?: string,
    asc?: boolean): Observable<Blob> {
    let url = `player/work/${companyUID}/staff-salary-month-overtime/list/csv?`
    if (startDate) {
      url += `&s=${encodeURIComponent(startDate)}`
    }
    if (endDate) {
      url += `&e=${encodeURIComponent(endDate)}`
    }
    if (asc !== undefined) {
      url += `&asc=${encodeURIComponent(asc)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPI<Blob>(url, {
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          let loginError = false
          if (res.status === 401 || res.status === 403) {
            loginError = true
          }
          if (loginError) {
            return Promise.reject(MessageError.from(ErrorCode.ServerLoginNeed))
          } else if (res.status !== 200) {
            return Promise.reject(MessageError.from(ErrorCode.ServerInternal, new Error(res.status + ' : ' + res.statusText)))
          }
          return res.data
        })
    )
  }

  public static workStaffSalaryMonthlyHolidayListCSV(companyUID: string,
    startDate?: string,
    endDate?: string,
    asc?: boolean): Observable<Blob> {
    let url = `player/work/${companyUID}/staff-salary-month-holiday/list/csv?`
    if (startDate) {
      url += `&s=${encodeURIComponent(startDate)}`
    }
    if (endDate) {
      url += `&e=${encodeURIComponent(endDate)}`
    }
    if (asc !== undefined) {
      url += `&asc=${encodeURIComponent(asc)}`
    }
    return fromPromise(
      PlayerHttp.instance().requestAPI<Blob>(url, {
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          let loginError = false
          if (res.status === 401 || res.status === 403) {
            loginError = true
          }
          if (loginError) {
            return Promise.reject(MessageError.from(ErrorCode.ServerLoginNeed))
          } else if (res.status !== 200) {
            return Promise.reject(MessageError.from(ErrorCode.ServerInternal, new Error(res.status + ' : ' + res.statusText)))
          }
          return res.data
        })
    )
  }
}
